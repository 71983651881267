export interface NotizDTO extends AbstractDTO {
    creator: UserResponseDTO|null,
    createTS: string|null,
    text: string|null
}
export interface FormVersionDTO extends AbstractDTO {
    archive: boolean|null,
    passOnChilds: boolean|null,
    template: boolean|null,
    rows: FormRowDTO[],
    released: boolean|null,
    versionNumber: number|null,
    form: FormDTO|null,
    releaseUser: UserResponseDTO|null,
    releaseDT: string|null,
    formName: string|null,
    formTyp: string|null,
    images: string[]
}
export interface FormDTO extends AbstractDTO {
    name: string|null,
    typ: number|null,
    text: string|null,
    archive: boolean|null,
    orgUnit: OrgUnitDTO|null,
    currentVersionNumber: number|null,
    activeVersionId: number|null,
    draftVersionId: number|null
}
export interface UpsertFormDTO extends FormVersionDTO {
    name: string|null,
    typ: number|null,
    passOnChilds: boolean|null
}
export interface FormRowRequestDTO {
    forms: FormDTO[],
    versions: FormVersionDTO[]
}
export interface FormRowDTO extends AbstractDTO {
    fields: AdditionalFieldDTO[],
    sortOrder: number|null,
    title: string|null,
    lineTop: string|null,
    lineBottom: string|null,
    isDescription: boolean|null,
    isDownload: boolean|null,
    fillRow: boolean|null,
    description: string|null,
    file: FileDTO|null
}
export interface EinsatzVorlageDTO extends AbstractDTO {
    name: string,
    passOnChilds: boolean,
    archive: boolean|null,
    orgUnit: OrgUnitDTO|null,
    forms: FormDTO[]
}
export interface EinsatzRequestDTO extends AbstractEntityTaskFormRequestDTO {
    userId: number|null,
    creatorId: number|null,
    vorlageId: number|null,
    archive: boolean|null,
    ouChain: boolean
}
export interface EinsatzDTO extends AbstractFormDTO {
    creator: UserResponseDTO|null,
    orgUnit: OrgUnitDTO|null,
    vorlage: EinsatzVorlageDTO|null,
    archive: boolean|null,
    sent: boolean|null,
    text: string|null,
    datumStart: string|null,
    datumEnde: string|null,
    zeitStart: string|null,
    zeitEnde: string|null
}
export interface ChatMessageDTO extends AbstractDTO {
    content: any,
    participantId: number,
    user: ChatUserDTO|null,
    timestamp: ChatTimestampDTO,
    chatId: number|null,
    mySelf: boolean,
    uploaded: boolean
}
export interface ChatUserDTO extends AbstractDTO {
    name: string
}
export interface ChatTimestampDTO {
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
    millisecond: number
}
export interface FullChatDTO extends ChatDTO {
    messages: ChatMessageDTO[],
    users: ChatUserDTO[]
}
export interface ChatDTO extends AbstractDTO {
    lastactor: ChatUserDTO|null,
    lastactionDT: string|null,
    name: string|null,
    referenceType: number|null,
    reference: number|null,
    personenkeis: PersonenkreisDTO[],
    orgUnits: OrgUnitDTO[],
    newMessages: boolean|null
}
export interface WachenCloudDateDTO extends AbstractDTO {
    dateType: AbstractDTO|null,
    date: string|null
}
export interface MedProduktEwmpgFileCategoryDTO extends MedProduktFileCategoryDTO {
    ewo: EwoDTO[]
}
export interface FileDTO extends AbstractDTO {
    name: string|null,
    identifier: string|null,
    path: string|null,
    size: number|null,
    description: string|null,
    uploadTimestamp: string|null,
    comment: string|null,
    validUntil: string|null,
    cloud: WachenCloudDTO|null,
    mimeType: string|null,
    clone: boolean
}
export interface UserFileCategoryDTO extends AbstractDTO {
    fileCategory: FileCategoryDTO|null,
    personenkeis: PersonenkreisDTO[],
    confidential: boolean,
    passOnChilds: boolean,
    audit: boolean|null,
    orgUnit: OrgUnitDTO|null
}
export interface WachenCloudDTO extends AbstractDTO {
    email: string|null,
    bezeichnung: string|null,
    orgUnit: OrgUnitDTO|null,
    status: number|null,
    personenkreise: PersonenkreisDTO[]
}
export interface MedProduktFileCategoryDTO extends AbstractDTO {
    fileCategory: FileCategoryDTO|null,
    confidential: boolean,
    passOnChilds: boolean,
    audit: boolean|null,
    medProdukt: MedProduktDTO[],
    geraeteart: EwoGeraeteartDTO[],
    foreignElement: boolean,
    orgUnit: OrgUnitDTO|null
}
export interface FileLexCategoryDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean|null,
    archive: boolean|null
}
export interface EwmpgFileCategoryDTO extends AbstractDTO {
    fileCategory: FileCategoryDTO|null,
    confidential: boolean,
    audit: boolean|null,
    ewo: EwoDTO[]
}
export interface ManualFileDTO extends AbstractDTO {
    archive: boolean,
    file: FileDTO|null,
    hersteller: string|null,
    modell: string|null,
    version: string|null,
    standVon: string|null,
    searchText: string|null,
    fileCategories: FileCategoryDTO[]|null
}
export interface FileCategoryDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean|null,
    typ: number|null,
    typString: string|null,
    archive: boolean|null,
    confidential: boolean|null,
    audit: boolean|null
}
export interface FileLexDTO extends AbstractDTO {
    name: string|null,
    beschreibung: string|null,
    ordnung: string|null,
    typ: number|null,
    wert: string|null,
    archive: boolean|null,
    passOnChilds: boolean|null,
    orgUnit: OrgUnitDTO|null,
    file: FileDTO|null,
    kategorie: FileLexCategoryDTO|null,
    tree: FileLexTreeDTO|null
}
export interface KfzFileCategoryDTO extends AbstractDTO {
    fileCategory: FileCategoryDTO|null,
    confidential: boolean,
    passOnChilds: boolean,
    audit: boolean|null,
    forAll: boolean,
    kfz: KfzResponseDTO[],
    kfzCategory: KfzCategoryDTO[],
    foreignElement: boolean,
    orgUnit: OrgUnitDTO|null
}
export interface WachenCloudTransferDTO extends AbstractDTO {
    modulId: number|null,
    file: FileDTO|null,
    object: AbstractDTO|null,
    manualFile: ManualFileDTO|null,
    originalGesehen: boolean|null,
    name: string|null,
    subObject: AbstractDTO|null,
    dates: WachenCloudDateDTO[],
    fileCategories: AbstractDTO[],
    validUntil: string|null
}
export interface MaterialFileCategoryDTO extends AbstractDTO {
    fileCategory: FileCategoryDTO|null,
    confidential: boolean,
    passOnChilds: boolean,
    audit: boolean|null,
    materialArt: MaterialArtDTO[],
    foreignElement: boolean,
    orgUnit: OrgUnitDTO|null
}
export interface WachenCloudFileDTO extends AbstractDTO {
    cloud: WachenCloudDTO|null,
    file: FileDTO|null
}
export interface EwmpgFileCategoryEwoDTO extends AbstractDTO {
    fileCategory: FileCategoryDTO|null,
    ewo: EwoDTO
}
export interface FileLexTreeDTO extends AbstractListItemDTO {
    parent: FileLexTreeDTO|null,
    bezeichnung: string|null,
    passOnChilds: boolean|null,
    konsolidierteAnsicht: boolean|null,
    orgUnit: OrgUnitDTO|null,
    childs: FileLexTreeDTO[]
}
export interface PermissionDTO extends AbstractDTO {
    modul: string,
    permission: number,
    name: string
}
export interface UserPermissionDTO {
    permissionGroup: number,
    ouId: number,
    userId: number,
    passOnChilds: boolean,
    validUntil: string|null
}
export interface MappedPermissionDTO extends AbstractDTO {
    permissionGroup: PermissionGroupDTO,
    personenkreis: PersonenkreisDTO,
    orgUnit: string,
    orgUnitId: number,
    passOnChilds: boolean,
    validUntil: string|null
}
export interface PermissionTextDTO {
    name: string,
    id: number
}
export interface PermissionGroupDTO extends AbstractDTO {
    name: string,
    passOnChilds: boolean|null,
    sortOrder: number|null,
    additionalText: string|null,
    permissions: PermissionDTO[],
    foreignPermissions: PermissionDTO[],
    ouMerkmale: OrgUnitMerkmalDTO[],
    rolle: PermissionGroupDTO[]
}
export interface ConstantsDTO {
    permissions: ModulPermission[],
    permissionGroups: PermissionGroupDTO[],
    maxFilesize: number,
    support: string|null
}
export interface OrgUnitTreeDTO extends AbstractResponseDto {
    name: string,
    childs: OrgUnitTreeDTO[],
    permissions: MappedPermissionDTO[],
    dto: any
}
export interface FilePermission {
    field: string,
    maxSize: number|null,
    maxFiles: number,
    allowedTypes: string[]
}
export interface ModulPermission {
    identifier: string,
    name: string,
    gruppe: string|null,
    permissions: PermissionTextDTO[],
    filePermission: FilePermission[],
    menuIgnorePermissions: []
}
export interface FobiUserDTO extends AbstractDTO {
    user: UserResponseDTO|null,
    status: FobiStatusDTO|null,
    files: EntityFileDTO[]
}
export interface FobiStatusDTO extends AbstractDTO {
    name: string,
    valid: boolean
}
export interface FobiKlasseMapDTO {
    id: number,
    fobiId: number,
    klasseId: number,
    stunden: number
}
export interface FobiZeitraumThemaKlasseDTO extends AbstractDTO {
    soll: number|null,
    tnSoll: boolean|null,
    maxGesamt: number|null,
    typ: number,
    klasse: FobiKlasseDTO|null,
    themen: FobiThemaDTO[],
    personenkeis: PersonenkreisDTO[],
    userIds: number[]
}
export interface FobiZeitraumUserRowDTO {
    user: UserResponseDTO,
    soll: FobiZeitraumThemaKlasseDTO[],
    haben: FobiZeitraumUserCellDTO[],
    ignore: boolean,
    gesamtStatus: number
}
export interface FobiZeitraumUserCellDTO {
    zuordnung: FobiZeitraumThemaKlasseDTO,
    stunden: number|null,
    status: number,
    themen: FobiThemaMapDTO[],
    klassen: FobiKlasseMapDTO[],
    ignore: boolean
}
export interface FobiThemaMapDTO {
    id: number,
    fobiId: number,
    themdId: number,
    stunden: number
}
export interface CampusDTO {
    course: string|null,
    courseId: string|null,
    users: any
}
export interface FobiZeitraumDTO extends AbstractDTO {
    archive: boolean|null,
    name: string,
    orgUnit: OrgUnitDTO|null,
    start: string,
    end: string,
    prevPlus: boolean,
    prevMinus: boolean,
    themaSpezGesamt: boolean,
    passOnChilds: boolean,
    prevZeitraum: FobiZeitraumDTO|null,
    ausschluss: FobiZeitraumAusschlussDTO[],
    gesamt: FobiZeitraumThemaKlasseDTO[],
    klassen: FobiZeitraumThemaKlasseDTO[],
    themen: FobiZeitraumThemaKlasseDTO[]
}
export interface FobiZeitraumAusschlussRequestDTO extends AbstractDTO {
    grund: string|null,
    userId: number|null,
    zeitraumId: number|null
}
export interface CampusResultDTO {
    themen: FobiThemaDTO[],
    users: UserResponseDTO[],
    overview: any
}
export interface FobiZeitraumAusschlussDTO extends AbstractDTO {
    grund: string|null,
    user: UserResponseDTO|null,
    zeitraumId: number|null
}
export interface FobiKombiThemaDTO extends AbstractDTO {
    archive: boolean|null,
    name: string,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean,
    themen: FobiThemaStundenDTO[]
}
export interface FobiDTO extends AbstractListItemDTO {
    archive: boolean|null,
    name: string|null,
    multi: boolean,
    orgUnit: OrgUnitDTO|null,
    kombiThema: FobiKombiThemaDTO|null,
    dateStart: string|null,
    dateEnd: string|null,
    timeStart: string|null,
    timeEnd: string|null,
    veranstalter: string|null,
    ort: string|null,
    dozent: string|null,
    kommentar: string|null,
    courseId: string|null,
    certificateId: string|null,
    certificateStatus: string|null,
    issued: string|null,
    expiry: string|null,
    campusUser: UserResponseDTO|null,
    dateNachweis: string|null,
    status: FobiStatusDTO|null,
    nachweisAbweichend: boolean|null,
    themen: FobiThemaStundenDTO[],
    klassen: FobiKlasseDTO[],
    user: FobiUserDTO[],
    nachweise: EntityFileDTO[]
}
export interface FobiThemaDTO extends AbstractDTO {
    archive: boolean|null,
    name: string,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean,
    courseId: string|null,
    durationInMinutes: number|null,
    published: boolean|null,
    hasTemplate: boolean|null
}
export interface FobiKlasseDTO extends AbstractDTO {
    archive: boolean|null,
    name: string,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean
}
export interface FobiRequestDTO {
    userId: number|null,
    ouId: number|null,
    statusId: number|null,
    dateFrom: string|null,
    dateTo: string|null,
    archive: boolean|null,
    ouChain: boolean,
    countOnly: boolean,
    list: boolean|null
}
export interface FobiThemaStundenDTO extends AbstractDTO {
    stunden: number|null,
    tn: boolean|null,
    thema: FobiThemaDTO|null
}
export interface EwmpgDateTypDTO extends AbstractDTO {
    dateTyp: DateTypDTO|null,
    intervallWarning: number|null,
    intervallReminder: number|null,
    intervallTyp: number|null,
    infotext: string|null,
    ewo: EwoDTO|null,
    foreignElement: boolean
}
export interface UserDateTypDTO extends AbstractDTO {
    dateTyp: DateTypDTO|null,
    name: string|null,
    formatted: string|null,
    orgUnit: OrgUnitDTO,
    intervallWarning: number|null,
    intervallReminder: number|null,
    intervallTyp: number|null,
    reminderPause: number|null,
    statusReportPause: number|null,
    lionScanPause: number|null,
    informUser: boolean,
    statusReport: boolean,
    infotext: string|null,
    passOnChilds: boolean,
    syncDate: boolean,
    fileCategory: FileCategoryDTO|null,
    personenkeis: PersonenkreisDTO[]
}
export interface DateTypDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean|null,
    typ: number|null,
    typString: string|null,
    archive: boolean|null,
    lion: boolean
}
export interface MaterialDateTypDTO extends AbstractDTO {
    dateTyp: DateTypDTO|null,
    orgUnit: OrgUnitDTO,
    name: string|null,
    formatted: string|null,
    intervallWarning: number|null,
    intervallReminder: number|null,
    intervallTyp: number|null,
    statusReport: boolean,
    infotext: string|null,
    passOnChilds: boolean,
    materialArt: MaterialArtDTO[],
    foreignElement: boolean,
    fileCategory: FileCategoryDTO|null
}
export interface KfzDateTypDTO extends AbstractDTO {
    dateTyp: DateTypDTO|null,
    orgUnit: OrgUnitDTO,
    name: string|null,
    formatted: string|null,
    intervallWarning: number|null,
    intervallReminder: number|null,
    intervallTyp: number|null,
    statusReport: boolean,
    infotext: string|null,
    passOnChilds: boolean,
    forAll: boolean,
    kfz: KfzResponseDTO[],
    kfzCategory: KfzCategoryDTO[],
    foreignElement: boolean,
    fileCategory: FileCategoryDTO|null
}
export interface MedProduktDateTypDTO extends AbstractDTO {
    dateTyp: DateTypDTO|null,
    orgUnit: OrgUnitDTO|null,
    name: string|null,
    formatted: string|null,
    intervallWarning: number|null,
    intervallReminder: number|null,
    intervallTyp: number|null,
    infotext: string|null,
    passOnChilds: boolean,
    medProdukt: MedProduktDTO[],
    geraeteart: EwoGeraeteartDTO[],
    foreignElement: boolean,
    fileCategory: FileCategoryDTO|null
}
export interface MedProduktEwmpgDateTypDTO extends MedProduktDateTypDTO {
    ewo: EwoDTO|null
}

export interface SkillDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean|null,
    archive: boolean|null
}
export interface UserPhoneListDTO extends UserResponseDTO {
    emailBusiness: string|null,
    phone: string|null,
    mobile: string|null,
    dateOfBirth: string|null
}
export interface UserQualificationDTO extends AbstractDTO {
    qualification: QualificationDTO|null,
    validFrom: string|null,
    validTo: string|null,
    comment: string|null,
    user: UserResponseDTO|null,
    status: string,
    statusNumeric: number|null,
    icon: string|null
}
export interface UserPermissionGroupDTO {
    permissionGroup: PermissionGroupDTO|null,
    orgUnit: OrgUnitDTO|null,
    user: UserResponseDTO|null,
    passOnChilds: boolean
}
export interface UserEditDTO extends UserProfileDTO {
    personalNo: string|null,
    externNo: string|null,
    vivendiNo: string|null,
    campusId: string|null,
    dateEntry: string|null,
    dateExit: string|null,
    jobScope: string|null,
    keinFahrzeugfuehrer: boolean,
    licenseClass: string|null,
    licenseNo: string|null,
    licenseIssuingAuthority: string|null,
    licenseIssuingDate: string|null,
    rfid: RfidDTO|null,
    forceProfileCheck: boolean,
    forcePasswordChange: boolean,
    invoiceOrgUnit: OrgUnitDTO|null,
    qualis: UserQualificationDTO[]|null,
    archive: boolean|null
}
export interface UserDressSizeDTO {
    hose: string|null,
    softshelljacke: string|null,
    einsatzjacke: string|null,
    einsatzweste: string|null,
    poloshirt: string|null,
    tshirt: string|null,
    pullover: string|null,
    schuhe: string|null,
    hemd: string|null,
    arbeitshandschuhe: string|null,
    einmalhandschuhe: string|null,
    helm: string|null,
    muetzen: string|null,
    guertel: string|null,
    fleecejacke: string|null,
    user: UserResponseDTO|null
}
export interface PersonenkreisDataDTO extends PersonenkreisDTO {
    users: UserResponseDTO[],
    skills: SkillDTO[],
    qualis: QualificationDTO[],
    archive: boolean
}
export interface UserAdditionalFieldDTO extends AbstractDTO {
    field: AdditionalFieldDTO,
    value: string
}
export interface UserDateDTO extends AbstractDateEntityDTO {
    dateTyp: UserDateTypDTO,
    user: UserResponseDTO,
    lastreminder: string|null
}
export interface UserSkillDTO extends AbstractDTO {
    skill: SkillDTO|null,
    comment: string|null,
    user: UserResponseDTO|null
}
export interface UserPinDTO extends AbstractDTO {
    pin: string,
    pinConfirm: string
}
export interface UserFileDTO extends AbstractEntityFileDTO {
    user: UserResponseDTO|null,
    categoryMap: UserFileCategoryDTO[]
}
export interface QualificationDTO extends AbstractListItemDTO {
    name: string|null,
    typ: number|null,
    rank: number|null,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean|null,
    archive: boolean|null
}
export interface UserProfileDTO extends UserResponseDTO {
    lockProfile: boolean,
    lastname: string|null,
    firstname: string|null,
    email: string|null,
    emailBusiness: string|null,
    alertEmailBusiness: boolean|null,
    salutation: string|null,
    dateOfBirth: string|null,
    placeOfBirth: string|null,
    mobile: string|null,
    phone: string|null,
    street: string|null,
    houseNo: string|null,
    city: string|null,
    plz: string|null,
    alertSms: boolean|null,
    alertEmail: boolean|null,
    validSms: boolean,
    qrCode: string|null,
    dressSize: UserDressSizeDTO|null,
    profilePhoto: FileDTO|null,
    consentPublicBirthday: boolean,
    consentPublicMobile: boolean,
    consentPublicPhone: boolean,
    consentProfilePhoto: boolean,
    divaModus: number|null,
    profilGeprueft: boolean|null
}
export interface UserOrgUnitDTO {
    userId: number,
    ouId: number
}
export interface PersonenkreisDTO extends AbstractListItemDTO {
    name: string|null,
    text: string|null,
    group: string|null,
    ouText: string|null,
    temporary: boolean,
    frontendRef: number|null,
    specialTyp: number|null,
    user: UserResponseDTO|null,
    orgUnit: OrgUnitDTO|null,
    skill: SkillDTO,
    qualification: QualificationDTO,
    permissionGroup: PermissionGroupDTO,
    dienst: DienstDTO|null,
    checkin: CheckinDTO|null,
    kfz: KfzResponseDTO|null,
    kennung: KfzKennungDTO|null,
    passOnChilds: boolean
}
export interface PasswordForgottenDTO extends AbstractDTO {
    sEmail: string|null,
    sPassword: string|null,
    sPasswordConfirm: string|null,
    token: string|null
}
export interface UserPasswordDTO extends AbstractDTO {
    passwordOld: string|null,
    password: string|null,
    passwordConfirm: string|null,
    passwortGeaendert: boolean|null
}
export interface DesiRequestDTO extends AbstractEntityTaskFormRequestDTO {
    archive: boolean|null,
    taskId: number|null,
    onlyUnconfirmed: boolean|null,
    ouChain: boolean|null
}
export interface DesiRoutineDTO extends AbstractDTO {
    archive: boolean|null,
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    disinfectant: DisinfectantDTO|null,
    concentration: ConcentrationDTO|null,
    einwirkZeit: EinwirkzeitDTO|null,
    type: TypeDTO|null,
    alternatives: DisinfectantConcentrationTimeTripleDTO[]
}
export interface DisinfectantConcentrationTimeTripleDTO extends AbstractDTO {
    disinfectant: DisinfectantDTO|null,
    concentration: ConcentrationDTO|null,
    einwirkZeit: EinwirkzeitDTO|null
}
export interface ConcentrationDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null,
    quantifier: number|null
}
export interface DiseaseDTO extends AbstractListItemDTO {
    name: string|null,
    synonym: string|null,
    transmission: string|null,
    comment: string|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null
}
export interface DisinfectantDTO extends AbstractListItemDTO {
    name: string|null,
    manufacturer: string|null,
    comment: string|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null
}
export interface EinwirkzeitDTO extends AbstractDTO {
    archive: boolean|null,
    name: string,
    orgUnit: OrgUnitDTO|null,
    quantifier: number|null
}
export interface ScopeDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null
}
export interface DesiDTO extends AbstractDTO {
    creator: UserResponseDTO|null,
    archive: boolean|null,
    sent: boolean|null,
    category: number|null,
    orgUnit: OrgUnitDTO|null,
    opNumber: string|null,
    date: string|null,
    time: string|null,
    kfz: KfzResponseDTO|null,
    kennung: KfzKennungDTO|null,
    user1: UserResponseDTO|null,
    user1Text: string|null,
    user2: UserResponseDTO|null,
    user2Text: string|null,
    user3: UserResponseDTO|null,
    user3Text: string|null,
    advice: DesiAdviceDTO|null,
    desiRoutine: DesiRoutineDTO|null,
    task: TaskDTO|null,
    type: TypeDTO|null,
    disinfectant: DisinfectantDTO|null,
    concentration: ConcentrationDTO|null,
    einwirkZeit: EinwirkzeitDTO|null,
    desinfektorInfo: boolean|null,
    erkrankungVorabbekannt: boolean|null,
    comment: string|null,
    zusatzdaten: string|null,
    sentmail: boolean|null,
    seen: boolean|null,
    validateTS: string|null,
    validator: UserResponseDTO|null,
    scopes: ScopeDTO[],
    protections: ProtectionDTO[],
    medProdukts: MedProduktDTO[],
    materials: MaterialDTO[]
}
export interface DesiTaskMapDTO extends AbstractDTO {
    task: TaskDTO|null,
    desi: DesiDTO|null
}
export interface DesiAdviceDTO extends AbstractListItemDTO {
    orgUnit: OrgUnitDTO|null,
    disease: DiseaseDTO|null,
    disinfectant: DisinfectantDTO|null,
    concentration: ConcentrationDTO|null,
    einwirkZeit: EinwirkzeitDTO|null,
    desinfektorInfo: boolean|null,
    routinedesi: boolean|null,
    zusatzdaten: string|null,
    ampel: AmpelDTO|null,
    scope: ScopeDTO[],
    protection: ProtectionDTO[],
    archiv: boolean
}
export interface TypeDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null
}
export interface ProtectionDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null
}
export interface DesiInheritanceDTO extends AbstractDTO {
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean|null,
    selection: OrgUnitDTO[],
    forcedBy: OrgUnitDTO[]
}
export interface AmpelDTO extends AbstractDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null
}
export interface MedProduktDateDTO extends AbstractDateEntityDTO {
    dateTyp: MedProduktDateTypDTO|null,
    ewmpgDateTyp: EwmpgDateTypDTO|null,
    dateText: string|null,
    medProdukt: MedProduktDTO,
    childs: MedProduktDateDTO[],
    locked: boolean|null
}
export interface MedProduktLebensdauerDTO extends MedProduktDateDTO {
    switchDate: string|null
}
export interface MedProduktOutsourcingDTO extends AbstractDTO {
    medProdukt: MedProduktDTO|null,
    outsourcing: OutsourcingDTO|null,
    inheritCategories: boolean,
    caterory: OutsourcingCategoryDTO[]
}
export interface MedProduktStandortDTO extends AbstractDTO {
    name: string,
    passOnChilds: boolean,
    temporary: boolean,
    kfz: KfzDTO|null,
    orgUnit: OrgUnitDTO|null
}
export interface MedProduktQrCodeDTO extends AbstractDTO {
    code: string
}
export interface MedProduktFileDTO extends AbstractEntityFileDTO {
    medProdukt: MedProduktDTO|null,
    childs: MedProduktFileDTO[],
    categoryMap: MedProduktEwmpgFileCategoryDTO[]
}
export interface MedProduktDTO extends AbstractListItemDTO {
    ewo: EwoDTO|null,
    parent: MedProduktDTO|null,
    softwareVersion: EwoSoftwareversionDTO|null,
    orgUnit: OrgUnitDTO|null,
    archive: boolean,
    archiveReason: string|null,
    temporary: boolean|null,
    holder: boolean,
    createChilds: boolean,
    name: string|null,
    serienNummer: string|null,
    udi: string|null,
    inventarNummer: string|null,
    anschaffungsJahr: string|null,
    inbetriebnahmeDatum: string|null,
    produktionsDatum: string|null,
    ausserbetriebnahmeDatum: string|null,
    kommentar: string|null,
    summary: string,
    investNummer: string|null,
    qrCode: MedProduktQrCodeDTO|null,
    location: EntityStandortDTO|null,
    locationText: string|null,
    primStandort: EntityStandortDTO|null,
    profilePhoto: FileDTO|null,
    outsourcingLocation: OutsourcingDTO|null,
    ewoObjektbezeichnung: string,
    ewoHersteller: string,
    ewoGeraeteart: string,
    childs: MedProduktDTO[],
    differentChilds: string[],
    deleteChilds: MedProduktDTO[],
    independentChilds: number[],
    existingChilds: MedProduktDTO[],
    additionalInfos: string|null
}
export interface TagOuDTO extends AbstractDTO {
    archive: boolean|null,
    tag: TagDTO|null,
    vorgabeText: string|null,
    email: boolean,
    sms: boolean,
    maiLExtern: string|null,
    own: boolean,
    info: PersonenkreisDTO[]
}
export interface LevelklasseDTO extends AbstractDTO {
    name: string|null,
    archive: boolean|null
}
export interface BewertungDTO extends AbstractDTO {
    name: string|null,
    archive: boolean|null
}
export interface CountingDTO {
    name: string|null,
    count: number|null,
    dto: any
}
export interface LevelDTO extends AbstractDTO {
    archive: boolean|null,
    parent: LevelDTO|null,
    orgUnit: OrgUnitDTO|null,
    name: string|null,
    bewertungType: number|null,
    vorgabeText: string|null,
    klasse: LevelklasseDTO|null,
    form: FormDTO|null,
    info: PersonenkreisDTO[],
    tags: TagDTO[],
    status: StatusDTO[],
    required: boolean|null,
    public: boolean|null,
    defaultStatus: StatusDTO|null,
    maxDepth: boolean|null,
    defaultZustaendig: PersonenkreisDTO|null,
    allLevelsRequired: boolean|null
}
export interface StatusDTO extends AbstractDTO {
    name: string|null,
    archive: boolean|null
}
export interface ReportRequestDTO extends AbstractEntityTaskFormRequestDTO {
    userId: number|null,
    userIdAll: boolean|null,
    creatorId: number|null,
    levelId: number|null,
    classId: number|null,
    includeOpen: boolean,
    includeDone: boolean,
    ouChain: boolean,
    onlyPublic: boolean,
    specialMode: number|null,
    refId: number|null,
    forceFill: boolean|null
}
export interface ReportDTO extends AbstractFormDTO {
    orgUnit: OrgUnitDTO|null,
    archive: boolean|null,
    level1: LevelDTO|null,
    level2: LevelDTO|null,
    level3: LevelDTO|null,
    level4: LevelDTO|null,
    level5: LevelDTO|null,
    vorgabeText: string|null,
    done: boolean|null,
    sent: boolean|null,
    status: StatusDTO|null,
    text: string|null,
    risiko: string|null,
    chance: string|null,
    tragweiteRisiko: TragweiteDTO|null,
    ewRisiko: EintrittswahrscheinlichkeitDTO|null,
    tragweiteChance: TragweiteDTO|null,
    ewChance: EintrittswahrscheinlichkeitDTO|null,
    bewertung: BewertungDTO|null,
    klassifikationRisiko: string|null,
    klassifikationChance: string|null,
    tags: TagDTO[],
    zustaendigkeit: PersonenkreisDTO|null,
    eskalationsStufe: number|null,
    ereignisDatum: string|null,
    files: EntityFileDTO[],
    notiz: string|null,
    creator: UserResponseDTO|null,
    createTS: string|null,
    _oInfoAnPersonenkreise: PersonenkreisDTO[],
    _oInfoAnEmails: string[]
}
export interface MeldewegDTO extends AbstractDTO {
    email: boolean,
    sms: boolean,
    emailAdresse: string|null,
    personenkreis: PersonenkreisDTO|null
}
export interface TragweiteDTO extends AbstractDTO {
    name: string|null,
    type: number|null,
    score: number|null,
    archive: boolean|null
}
export interface ReportNotizDTO extends NotizDTO {
    reportId: number|null
}
export interface EwTragweiteBewertungDTO {
    bewertungen: BewertungDTO[],
    tragweite: TragweiteDTO[],
    eintrittswahrscheinlichkeiten: EintrittswahrscheinlichkeitDTO[]
}
export interface LevelOuDTO extends AbstractDTO {
    archive: boolean|null,
    level: LevelDTO|null,
    orgUnit: OrgUnitDTO|null,
    vorgabeText: string|null,
    email: boolean,
    sms: boolean,
    own: boolean,
    required: boolean,
    maiLExtern: string|null,
    zustaendig: PersonenkreisDTO|null,
    eskalation: PersonenkreisDTO|null,
    parentZustaendig: PersonenkreisDTO|null,
    parentEskalation: PersonenkreisDTO|null,
    tagsOu: TagOuDTO[],
    info: PersonenkreisDTO[]
}
export interface TagDTO extends AbstractDTO {
    archive: boolean|null,
    name: string|null,
    vorgabeText: string|null,
    form: FormDTO|null,
    bewertungType: number|null,
    info: PersonenkreisDTO[]
}
export interface EintrittswahrscheinlichkeitDTO extends AbstractDTO {
    name: string|null,
    type: number|null,
    score: number|null,
    archive: boolean|null
}
export interface AbstractDashboardEntryDTO {
    modul: number|null,
    typ: number|null,
    schweregrad: number|null,
    status: string|null,
    gueltigBis: string|null,
    user: UserResponseDTO|null,
    kfz: KfzResponseDTO|null,
    medProdukt: MedProduktDTO|null,
    material: MaterialDTO|null,
    orgUnit: OrgUnitDTO|null,
    name: string|null
}
export interface DashboardFileDTO extends AbstractDashboardEntryDTO {
    fileCategory: FileCategoryDTO|null
}
export interface DashboardCount {
    typ: number|null,
    schweregrad: number|null,
    status: string|null,
    count: number|null
}
export interface DashboardDateDTO extends AbstractDashboardEntryDTO {
    userQualification: UserQualificationDTO|null,
    userDateTyp: UserDateTypDTO|null,
    kfzDateTyp: KfzDateTypDTO|null,
    medProduktDateTyp: MedProduktDateTypDTO|null,
    ewmpgDateTyp: EwmpgDateTypDTO|null,
    materialDateTyp: MaterialDateTypDTO|null
}
export interface RfidDTO extends AbstractDTO {
    publicId: string|null
}
export interface EntityTtoCheckinTaskDTO {
    entityTto: EntityTaskTemplateObjectDTO|null,
    checkinId: number|null,
    medProdukt: MedProduktDTO|null,
    material: MaterialDTO|null,
    kfz: KfzResponseDTO|null
}
export interface TaskReportMapDTO extends AbstractDTO {
    task: TaskDTO|null,
    report: ReportDTO|null
}
export interface EntityTaskFormRequestDTO extends AbstractEntityTaskFormRequestDTO {
    anyKfz: boolean|null,
    anyMedProdukt: boolean|null,
    anyMaterial: boolean|null,
    hygieneOnly: boolean|null,
    statusId: number|null,
    ouChain: boolean,
    kategorie: number|null
}
export interface TaskProcessDTO extends AbstractFormDTO {
    task: TaskDTO|null,
    statusId: number|null,
    text: string|null,
    zurueckBis: string|null
}
export interface TaskRequestDTO {
    taskId: number|null,
    userId: number|null,
    userIdAll: boolean|null,
    ouId: number|null,
    masterId: number|null,
    statusId: number|null,
    typId: number|null,
    archive: boolean,
    template: boolean,
    onlyPublic: boolean,
    hygieneOnly: boolean|null,
    countOnly: boolean|null,
    groupCount: boolean|null,
    ouChain: boolean,
    kategorie: number|null
}
export interface TaskNotizDTO extends NotizDTO {
    taskId: number|null
}
export interface EntityTaskTemplateDTO extends AbstractDTO {
    archive: boolean,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean,
    modul: number|null,
    title: string|null,
    text: string|null,
    form: FormDTO|null,
    kategorie: number|null,
    desiRoutine: DesiRoutineDTO|null,
    alternativeDesiAllowed: number|null,
    alternativeDaysBefore: number|null,
    files: EntityFileDTO[],
    entityTasks: EntityTaskTemplateObjectDTO[],
    geerbt: EntityTaskTemplateObjectDTO[],
    scopes: ScopeDTO[]
}
export interface TaskStornoDTO extends AbstractDTO {
    grund: string|null
}
export interface TaskDTO extends AbstractFormDTO {
    archive: boolean,
    master: TaskDTO|null,
    orgUnit: OrgUnitDTO|null,
    user: UserResponseDTO|null,
    typ: number|null,
    typText: string|null,
    zModus: number|null,
    kategorie: number|null,
    status: TaskStatusDTO|null,
    template: boolean|null,
    massnahme: boolean|null,
    passOnChilds: boolean|null,
    title: string|null,
    text: string|null,
    quelle: string|null,
    risiken: string|null,
    chance: string|null,
    storno: boolean|null,
    public: boolean|null,
    faelligkeit: string|null,
    gueltigAb: string|null,
    gueltigBis: string|null,
    zurueckBis: string|null,
    zustaendig: PersonenkreisDTO[],
    verantwortlich: PersonenkreisDTO[],
    betroffen: PersonenkreisDTO[],
    orgUnits: OrgUnitDTO[],
    scopes: ScopeDTO[],
    files: EntityFileDTO[],
    form: FormDTO|null,
    formVersion: FormVersionDTO|null,
    desiRoutine: DesiRoutineDTO|null,
    alternativeDesiAllowed: number|null,
    alternativeDaysBefore: number|null,
    createTS: string|null,
    weitergabeModus: number|null,
    runOnLocation: boolean|null
}
export interface EntityTaskTemplateObjectDTO extends TaskTemplateDTO {
    kfz: KfzResponseDTO|null,
    medProdukt: MedProduktDTO|null,
    material: MaterialDTO|null,
    kfzCategory: KfzCategoryDTO|null,
    medProduktArt: EwoGeraeteartDTO|null,
    materialArt: MaterialArtDTO|null,
    checkinBezug: boolean|null,
    form: FormDTO|null
}
export interface TaskFormRequestDTO extends AbstractEntityTaskFormRequestDTO {
    formId: number|null,
    masterId: number|null
}
export interface TaskChatDTO extends AbstractDTO {
    title: string|null,
    taskId: number|null,
    personenkreis: PersonenkreisDTO[],
    orgUnits: OrgUnitDTO[],
    chat: ChatDTO|null
}
export interface TaskStatusDTO extends AbstractDTO {
    name: string|null,
    sortOrder: number|null
}
export interface EntityTaskDTO extends TaskDTO {
    kfz: KfzResponseDTO|null,
    medProdukt: MedProduktDTO|null,
    material: MaterialDTO|null,
    form: FormDTO|null,
    checkinBezug: boolean|null
}
export interface TaskTemplateDTO extends TaskDTO {
    rrule_freq: string|null,
    rrule_interval: number|null,
    rrule_bysetpos: string|null,
    rrule_byweekday: string|null,
    rrule_bymonth: string|null,
    rrule_bymonthday: number|null,
    rrule_daytype: string|null,
    rrule_dynamik: string|null,
    rrule_daysBefore: number|null,
    rrule_prevGen: string|null,
    rrule_prevFaellig: string|null,
    rrule_nextGen: string|null,
    rrule_nextFaellig: string|null,
    rrule_lastGen: string|null,
    rrule_lastFaellig: string|null,
    rrule_text: string|null,
    ouMerkmals: OrgUnitMerkmalDTO[],
    invalidatePrev: boolean|null
}
export interface TaskTreeDTO {
    taskId: number|null,
    status: TaskStatusDTO|null,
    orgUnit: OrgUnitDTO|null,
    childs: TaskTreeDTO[]
}
export interface LoginDTO extends AbstractDTO {
    username: string|null,
    qrCode: string|null,
    password: string|null,
    pin: string|null,
    location: number|null
}
export interface AuthorizedUserDto {
    token: string|null|any,
    ou: OrgUnitDTO|null,
    user: UserResponseDTO,
    ouArr: [],
    constants: ConstantsDTO,
    childChain: [],
    permissions: PermissionDTO[],
    forcePasswordChange: boolean,
    forceProfileCheck: boolean
}
export interface AbstractEntityFileDTO extends AbstractDTO {
    archive: boolean,
    originalGesehen: boolean|null,
    file: FileDTO|null,
    manualFile: ManualFileDTO|null,
    downloadFile: FileDTO|null,
    fileCategory: FileCategoryDTO|null,
    fileCategories: FileCategoryDTO[]|null,
    frontendRef: number|null,
    validUntil: string|null,
    fileId: number|null,
    uploadDate: string|null,
    comment: string|null,
    status: string|null,
    statusNumeric: number|null,
    icon: string|null,
    skip: boolean,
    skipReason: string|null,
    skipUntil: string|null
}
export interface StandortwechselDTO extends AbstractDTO {
    neuerStandort: EntityStandortDTO|null,
    material: MaterialDTO[],
    medProdukt: MedProduktDTO[]
}
export interface ImportErrorDTO {
    zeile: string,
    errors: []
}
export interface AbstractTimestampDTO extends AbstractDTO {
    createdAt: string|null,
    creator: UserResponseDTO|null,
    modifiedAt: string|null,
    modifier: UserResponseDTO|null
}
export interface AbstractFormDTO extends AbstractDTO {
    addFields: AdditionalFieldResultDTO[],
    rows: FormRowDTO[]
}
export interface EntityFileDTO extends AbstractDTO {
    file: FileDTO|null,
    frontendRef: number|null,
    comment: string|null
}
export interface FieldErrorDTO {
    name: string,
    status: string
}
export interface HistoryEntryDTO {
    id: number|null,
    timestamp: string|null,
    user: UserResponseDTO|null,
    removed: boolean|null,
    archived: boolean|null,
    archiveReason: string|null,
    class: string|null,
    fk: number|null,
    parentClass: string|null,
    parentFk: number|null,
    changes: []
}
export interface EntityStandortDTO extends AbstractListItemDTO {
    archive: boolean,
    name: string,
    passOnChilds: boolean,
    kfz: KfzDTO|null,
    outsourcing: OutsourcingDTO|null,
    orgUnit: OrgUnitDTO|null,
    material: MaterialDTO|null,
    user: UserResponseDTO|null,
    dto: any,
    kfzStandort: boolean,
    medProduktStandort: boolean,
    materialStandort: boolean,
    customLocation: boolean,
    abstract: boolean,
    icon: string|null
}
export interface AbstractResponseDto extends AbstractDTO {
    locked: boolean|null
}
export interface ArchiveReasonDTO {
    id: string|null,
    reason: string|null
}
export interface AbstractDateEntityDTO extends AbstractDTO {
    date: string|null,
    skip: boolean,
    skipReason: string|null,
    skipUntil: string|null,
    status: string,
    statusNumeric: number|null,
    icon: string|null,
    file: FileDTO|null,
    fileComment: string|null,
    externFile: AbstractEntityFileDTO|null
}
export interface ErrorDTO {
    text: string
}
export interface HistoryRequestDTO {
    dateFrom: string|null,
    dateTo: string|null,
    id: number|null
}
export interface GegenstandListItemDTO {
    id: string|null,
    name: string|null,
    text: string|null,
    searchText: string|null,
    type: string|null,
    dto: any
}
export interface UserResponseDTO extends AbstractListItemDTO {
    username: string|null,
    lastname: string|null,
    firstname: string|null,
    formatted: string,
    fromattedLong: string,
    formattedLong: string,
    formattedShort: string,
    sFormattedShort: string
}
export interface AbstractDTO {
    id: number|null,
    tempId: string|null,
    originalId: number|null,
    addFieldId: number|null,
    warnings: string[],
    statusDate: number|null,
    statusFile: number|null,
    statusEmeldReport: number|null
}
export interface HistoryChangeDTO {
    id: number|null,
    field: string|null,
    fieldName: string|null,
    oldValue: string|null,
    newValue: string|null
}
export interface AbstractListItemDTO extends AbstractDTO {
    type: string|null,
    icon: string|null,
    iconPlain: string|null,
    text: string|null,
    searchText: string|null,
    typeText: string|null
}
export interface FieldValueConditionDTO {
    field: string,
    value: string,
    entity: string
}
export interface ResponseDTO {
    data: any|null,
    fieldErrors: []|null,
    error: string|ErrorDTO[]|null
}
export interface AbstractEntityTaskFormRequestDTO {
    dateFrom: string|null,
    dateTo: string|null,
    kfzId: number|null,
    medProduktId: number|null,
    materialId: number|null,
    ouId: number|null,
    countOnly: boolean|null,
    groupCount: boolean|null,
    archive: boolean|null,
    statusId: number|null,
    kategorie: number|null,
    hygieneOnly: boolean|null
}
export interface SimpleStandortwechselDTO extends AbstractDTO {
    location: EntityStandortDTO|null,
    material: MaterialDTO|null,
    medProdukt: MedProduktDTO|null
}
export interface AdditionalFieldResultDTO extends AbstractDTO {
    field: AdditionalFieldDTO|null,
    fieldValue: string|null,
    fieldTyp: number|null,
    identifier: string|null,
    medProdukt: MedProduktDTO[],
    kfz: KfzResponseDTO[],
    material: MaterialDTO[],
    kennung: KfzKennungDTO[],
    user: UserResponseDTO|null,
    dienst: DienstDTO|null,
    qualifikation: QualificationDTO|null,
    file: FileDTO|null,
    userText: string|null,
    kfzKmBeginn: number|null,
    kfzKmEnde: number|null
}
export interface AdditionalFieldDTO extends AbstractDTO {
    fieldTyp: number|null,
    multi: boolean|null,
    name: string|null,
    typ: number|null,
    sortOrder: number|null,
    identifier: string|null,
    required: boolean|null,
    checkinField: string|null,
    bCheckinBezug: boolean|null,
    vorbelegung: string|null,
    filterGeraeteart: EwoGeraeteartDTO|null,
    filterMaterialArt: MaterialArtDTO|null,
    options: string[]
}
export interface VivendiDienstDTO extends AbstractDTO {
    name: string|null,
    kurzname: string|null,
    vivendiId: number|null,
    text: string|null
}
export interface SuchenPersonTokenDTO extends AbstractDTO {
    status: number|null,
    token: string|null,
    kommentar: string|null
}
export interface SuchenPersonDTO extends AbstractDTO {
    user: UserResponseDTO|null,
    status: number|null,
    statusText: string|null,
    ignoreReason: string|null,
    token: string|null,
    anfrageTs: string|null,
    antwortTs: string|null,
    suche: SuchenDTO|null,
    auktion: AuktionDTO|null,
    antwortKommentar: string|null,
    rang: number|null,
    link: string|null
}
export interface VivendiOuSyncDTO extends AbstractDTO {
    vivendiBereich: string|null
}
export interface AuktionDTO extends AbstractDTO {
    orgUnit: OrgUnitDTO|null,
    dienst: DienstDTO|null,
    archive: boolean,
    portal: boolean,
    diva: boolean,
    lock: boolean|null,
    inform: number|null,
    modus: number|null,
    meldeweg: number|null,
    warten: number|null,
    datumStart: string|null,
    datumEnde: string|null,
    zeitStart: string|null,
    zeitEnde: string|null,
    kommentar: string|null,
    statusText: string|null,
    additionalOus: OrgUnitDTO[],
    suchen: SuchenDTO[],
    creator: UserResponseDTO|null,
    createTS: string|null
}
export interface SuchenDTO extends AbstractDTO {
    status: number|null,
    anzahl: number|null,
    funde: number|null,
    qualiJump: boolean|null,
    lock: boolean|null,
    personenkreis: PersonenkreisDTO[]
}
export interface VivendiDienstOuMapDTO extends AbstractDTO {
    orgUnit: OrgUnitDTO|null,
    dienst: VivendiDienstDTO|null,
    typ: number|null
}
export interface OutsourcingDTO extends AbstractListItemDTO {
    company: string,
    phone: string|null,
    street: string|null,
    houseNo: string|null,
    city: string|null,
    plz: string|null,
    email: string|null,
    comment: string|null,
    typString: string|null,
    locationSummary: string|null,
    category: OutsourcingCategoryDTO[],
    passOnChilds: boolean,
    isoCert: boolean,
    archive: boolean,
    kfzStandort: boolean,
    medProduktStandort: boolean,
    materialStandort: boolean,
    sonstigesService: boolean,
    orgUnit: OrgUnitDTO|null
}
export interface OutsourcingCategoryDTO extends AbstractDTO {
    name: string,
    typ: number,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean|null
}
export interface TagebuchEintragRequestDTO {
    allOfOu: boolean|null,
    checkinId: number|null,
    tagebuchId: number|null,
    dateFrom: string|null,
    dateTo: string|null,
    onlyOpen: boolean|null,
    status: number|null,
    countOnly: boolean|null
}
export interface CheckinTaskDTO extends AbstractDTO {
    taskId: number|null,
    checkinId: number|null
}
export interface TagebuchNotizDTO extends NotizDTO {
    tagebuchEintragId: number|null
}
export interface TagebuchDTO extends AbstractTimestampDTO {
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    targetOus: OrgUnitDTO[],
    personenkreise: PersonenkreisDTO[],
    themen: string[]
}
export interface CheckinRequestDTO {
    userId: number|null,
    ouId: number|null,
    dateFrom: string|null,
    dateTo: string|null,
    onlyUnconfirmed: boolean|null,
    onlyOpen: boolean|null,
    archive: boolean|null,
    ouChain: boolean,
    countOnly: boolean|null,
    enrich: boolean|null
}
export interface CheckinPersonalDTO extends AbstractDTO {
    datumStart: string|null,
    zeitStart: string|null,
    fahrer: UserResponseDTO|null,
    fahrerText: string|null,
    beifahrer: UserResponseDTO|null,
    beifahrerText: string|null,
    dritter: UserResponseDTO|null,
    dritterText: string|null
}
export interface CheckinUserDTO extends AbstractTimestampDTO {
    user: UserResponseDTO|null,
    userText: string|null,
    position: string|null,
    datumStart: string|null,
    zeitStart: string|null
}
export interface CheckinDTO extends AbstractTimestampDTO {
    archive: boolean|null,
    orgUnit: OrgUnitDTO|null,
    dienst: DienstDTO|null,
    kennung: KfzKennungDTO|null,
    kfz: KfzResponseDTO|null,
    kmStart: number|null,
    kmEnde: number|null,
    datumStart: string|null,
    datumEnde: string|null,
    zeitStart: string|null,
    zeitEnde: string|null,
    textCheckin: string|null,
    textCheckout: string|null,
    checkout: boolean|null,
    checkoutTS: string|null,
    reviewed: boolean|null,
    reviewer: UserResponseDTO|null,
    reviewTS: string|null,
    textReview: string|null,
    fahrer: UserResponseDTO|null,
    fahrerText: string|null,
    beifahrer: UserResponseDTO|null,
    beifahrerText: string|null,
    dritter: UserResponseDTO|null,
    dritterText: string|null,
    undoneTaskCount: number|null,
    totaltaskCount: number|null,
    undoneTasksConfirmed: boolean|null,
    ohneFahrerlaubnis: boolean|null,
    undoneTagebuchCount: number|null,
    reportCount: number|null,
    checkinUsers: CheckinUserDTO[],
    actualCheckinUsers: CheckinUserDTO[]
}
export interface TagebuchEintragDTO extends AbstractTimestampDTO {
    datum: string|null,
    zeit: string|null,
    datumEnde: string|null,
    zeitEnde: string|null,
    text: string|null,
    tagebuchId: number|null,
    done: boolean|null,
    personenkreise: PersonenkreisDTO[],
    themen: string[]
}
export interface DienstDTO extends AbstractListItemDTO {
    archive: boolean|null,
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean,
    startZeit: string|null,
    endZeit: string|null,
    adresse: string|null,
    anzeigenVerfuegbarkeiten: boolean|null,
    anzeigenCheckin: boolean|null,
    beifahrerPflicht: boolean|null,
    kfzPflicht: boolean|null,
    kmPflicht: boolean|null,
    kennungPflicht: boolean|null,
    autoCheckoutStunden: number|null,
    ausnahmen: DienstAusnahmeDTO[],
    abfragezeiten: AbfragezeitenDTO[]
}
export interface OrgUnitEvdResponseDTO extends AbstractDTO {
    user: UserResponseDTO|null,
    nummer: string|null
}
export interface DienstAusnahmeDTO extends AbstractDTO {
    startZeit: string|null,
    endZeit: string|null,
    tag: string|null
}
export interface OrgUnitSettingsDTO extends AbstractDTO {
    sms: boolean|null,
    superUserPermissionGroup: PermissionGroupDTO|null
}
export interface OrgUnitMerkmalDTO extends AbstractDTO {
    name: string
}
export interface OrgUnitEvdDTO extends AbstractDTO {
    user: UserResponseDTO|null,
    vererbt: boolean|null,
    zeigeName: boolean|null,
    nummerUser: boolean|null,
    nummer: string|null
}
export interface OrgUnitExtendedDTO extends OrgUnitDTO {
    standortId: string|null,
    vertragsNummer: string|null,
    sapNummer: string|null,
    smsVertrag: string|null,
    rechnungsportalId: string|null,
    ansprechpartner: string|null,
    ansprechpartnerTelefon: string|null,
    ansprechpartnerEmail: string|null,
    ansprechpartnerAdresse: string|null,
    rechtstraeger: OrgUnitRechtstraegerDTO|null,
    dienstzuordnung: OrgUnitDienstzuordnungDTO|null,
    zeusId: number|null,
    module: string[],
    forbiddenModule: string[]
}
export interface OrgUnitRechtstraegerDTO extends AbstractDTO {
    name: string
}
export interface OrgUnitDienstzuordnungDTO extends AbstractDTO {
    name: string
}
export interface AbfragezeitenDTO extends AbstractDTO {
    archive: boolean|null,
    name: string|null,
    orgUnit: OrgUnitDTO|null,
    passOnChilds: boolean,
    d0: boolean,
    d1: boolean,
    d2: boolean,
    d3: boolean,
    d4: boolean,
    d5: boolean,
    d6: boolean
}
export interface OrgUnitTypDTO extends AbstractDTO {
    name: string,
    pseudo: boolean
}
export interface OrgUnitDTO extends AbstractResponseDto {
    name: string|null,
    sName: string|null,
    typ: OrgUnitTypDTO|null,
    type: string|null,
    icon: string|null,
    text: string|null,
    searchText: string|null,
    typeText: string|null,
    merkmale: OrgUnitMerkmalDTO[],
    smsAllowed: boolean|null
}
export interface EwoDTO extends AbstractListItemDTO {
    objektbezeichnung: EwoObjektbezeichnungDTO|null,
    geraeteart: EwoGeraeteartDTO|null,
    hersteller: EwoHerstellerDTO|null,
    ceNummer: string|null,
    einweisungsPflicht: number,
    verbot: boolean,
    kombi: boolean,
    maxNutzung: number|null,
    maxAlter: number|null,
    archive: boolean,
    kombiTyp: number,
    softwareversionen: EwoSoftwareversionDTO[],
    softwareSummary: string|null,
    summary: string|null,
    einzelEwo: EwoDTO[],
    profilePhoto: FileDTO|null,
    dates: EwmpgDateTypDTO[],
    fileCategories: EwmpgFileCategoryDTO[]
}
export interface EinweisungGegenstandFullDTO extends AbstractGegenstandDTO {
    einweisung: EinweisungDTO,
    einweiser: UserResponseDTO|null,
    einweiserTxt: string|null,
    confirmed: boolean
}
export interface EinweisungDTO extends AbstractListItemDTO {
    datum: string|null,
    archive: boolean|null,
    multiplikator: boolean,
    art: string|null,
    orgUnit: OrgUnitDTO|null,
    nachweis: FileDTO|null,
    gegenstaende: EinweisungGegenstandDTO[],
    user: EinweisungUserDTO[],
    nachweise: EinweisungFileDTO[],
    locked: boolean|null
}
export interface EinweisungOverviewRequestDTO extends AbstractDTO {
    configDTO: EinweisungConfigDTO|null,
    kfzId: number|null,
    medProduktId: number|null,
    ewoId: number|null,
    materialId: number|null,
    orgUnitId: number|null,
    standortId: number|null
}
export interface EinweisungFileDTO extends AbstractDTO {
    file: FileDTO|null,
    frontendRef: number|null,
    comment: string|null
}
export interface EinweisungUserDTO extends AbstractDTO {
    user: UserResponseDTO|null,
    confirmed: boolean
}
export interface AbstractGegenstandDTO extends AbstractDTO {
    medProdukt: MedProduktDTO|null,
    kfz: KfzDTO|null,
    material: MaterialDTO|null,
    kfzCategory: KfzCategoryDTO|null,
    materialKlasse: MaterialArtDTO|null,
    qualifikation: QualificationDTO|null
}
export interface EwoHerstellerDTO extends AbstractDTO {
    name: string,
    archive: boolean,
    phone: string|null,
    street: string|null,
    houseNo: string|null,
    city: string|null,
    plz: string|null,
    email: string|null,
    website: string|null,
    lieferant: boolean,
    hersteller: boolean,
    country: CountryDTO|null
}
export interface EwoSoftwareversionDTO extends AbstractDTO {
    name: string,
    archive: boolean
}
export interface EinweisungGegenstandDTO extends AbstractGegenstandDTO {
    einweiser: UserResponseDTO|null,
    einweiserTxt: string|null,
    confirmed: boolean
}
export interface EwoObjektbezeichnungDTO extends AbstractDTO {
    name: string,
    archive: boolean
}
export interface EinweisungOverviewResponseDTO extends AbstractDTO {
    users: EinweisungOverviewUser[],
    gegenstaende: AbstractGegenstandDTO[]
}
export interface EinweisungOverviewUser extends AbstractDTO {
    user: UserResponseDTO,
    columns: EinweisungDTO[]|UserQualificationDTO[]
}
export interface EwoGeraeteartDTO extends AbstractListItemDTO {
    name: string,
    archive: boolean
}
export interface EwoFileDTO extends AbstractDTO {
    file: FileDTO|null,
    fileCategories: FileCategoryDTO[]|null,
    ewo: EwoDTO|null,
    frontendRef: number|null,
    validUntil: string|null,
    comment: string|null,
    version: string|null,
    standVon: string|null
}
export interface CountryDTO extends AbstractDTO {
    name: string
}
export interface EinweisungConfigDTO extends AbstractDTO {
    name: string|null,
    archive: boolean|null,
    orgUnit: OrgUnitDTO,
    passOnChilds: boolean,
    personenkeis: PersonenkreisDTO[],
    gegenstaende: AbstractGegenstandDTO[],
    requestDto: EinweisungOverviewRequestDTO|null,
    optionDto: AbstractDTO|null
}
export interface KfzFileDTO extends AbstractEntityFileDTO {
    kfz: KfzResponseDTO|null,
    categoryMap: KfzFileCategoryDTO[]
}
export interface KfzResponseDTO extends AbstractListItemDTO {
    name: string|null,
    licensePlate: string|null,
    kennung: KfzKennungDTO|null
}
export interface KfzDTO extends KfzResponseDTO {
    archive: boolean,
    orgUnit: OrgUnitDTO|null,
    status: number|null,
    mpgLocation: boolean,
    nichtEinsatzbereitKeineTasks: boolean,
    materialLocation: boolean,
    fremdeigentum: boolean,
    typ: string|null,
    rfid: RfidDTO|null,
    kennung: KfzKennungDTO|null,
    location: EntityStandortDTO|null,
    locationText: string|null,
    chassisNumber: string|null,
    firstRegistrationDate: string|null,
    constructionYear: string|null,
    aufbauer: KfzAufbauerDTO|null,
    herstellerModell: KfzAufbauerDTO|null,
    builderNumber: string|null,
    caseMoved: boolean,
    caseMovedDate: string|null,
    comment: string|null,
    tire: string|null,
    keyCount: number|null,
    repInsurance: boolean,
    repInsuranceProvider: string|null,
    repInsuranceNumber: string|null,
    repInsuranceValidUntilDate: string|null,
    repInsuranceValidUntilKm: string|null,
    outOfServiceDate: string|null,
    profilePhoto: FileDTO|null,
    quali: QualificationDTO|null,
    investNummer: string|null,
    category: KfzCategoryDTO[]|null
}
export interface KfzDateDTO extends AbstractDateEntityDTO {
    dateTyp: KfzDateTypDTO,
    kfz: KfzResponseDTO
}
export interface KfzStandortDTO extends AbstractDTO {
    name: string|null,
    passOnChilds: boolean,
    temporary: boolean,
    orgUnit: OrgUnitDTO|null
}
export interface KfzAufbauerDTO extends AbstractDTO {
    date: string|null,
    name: string|null
}
export interface KfzOutsourcingDTO extends AbstractDTO {
    kfz: KfzResponseDTO|null,
    outsourcing: OutsourcingDTO|null,
    inheritCategories: boolean,
    caterory: OutsourcingCategoryDTO[]
}
export interface KfzCategoryDTO extends AbstractListItemDTO {
    name: string,
    abstract: boolean|null,
    passOnChilds: boolean,
    orgUnit: OrgUnitDTO|null
}
export interface FahrtenbuchDTO extends AbstractDTO {
    kfz: KfzResponseDTO|null,
    user: UserResponseDTO|null,
    timestamp_start: string|null,
    timestamp_end: string|null,
    km_start: number|null,
    km_end: number|null,
    kommentar: string|null,
    additional_info: string|null,
    checkin: CheckinDTO|null,
    result: AdditionalFieldResultDTO|null,
    einsatz: EinsatzDTO|null,
    task: TaskDTO|null,
    report: ReportDTO|null
}
export interface KfzKennungDTO extends AbstractListItemDTO {
    name: string,
    archive: boolean,
    passOnChilds: boolean,
    orgUnit: OrgUnitDTO|null
}
export interface MaterialAusgabeConfirmedDTO extends AbstractDTO {
    empfangConfirmed: boolean|null
}
export interface MaterialRequestDTO extends AbstractDTO {
    ouId: number|null,
    ouIds: []|null,
    includeChildOus: boolean|null,
    parentId: number|null,
    archive: boolean|null,
    withChilds: boolean|null,
    onlyIds: boolean|null,
    medProduktStandortFilter: boolean|null,
    materialStandortFilter: boolean|null,
    materialArtId_arr: [],
    materialKlasseId_arr: []
}
export interface MaterialFileDTO extends AbstractEntityFileDTO {
    material: MaterialDTO|null,
    categoryMap: MaterialFileCategoryDTO[]
}
export interface MaterialOutsourcingDTO extends AbstractDTO {
    material: MaterialDTO|null,
    outsourcing: OutsourcingDTO|null,
    inheritCategories: boolean,
    caterory: OutsourcingCategoryDTO[]
}
export interface MaterialAusgabeDTO extends AbstractDTO {
    parent: MaterialAusgabeDTO|null,
    hasChilds: boolean|null,
    material: MaterialDTO|null,
    ausgeber: UserResponseDTO|null,
    empfaenger: UserResponseDTO|null,
    empfaengerText: string|null,
    ruecknehmer: UserResponseDTO|null,
    dateAusgabe: string|null,
    empfangConfirmed: boolean|null,
    dateRuecknahme: string|null,
    verlust: boolean|null,
    kommentar: string|null,
    location: EntityStandortDTO|null,
    locationText: string|null
}
export interface MaterialDateDTO extends AbstractDateEntityDTO {
    dateTyp: MaterialDateTypDTO,
    material: MaterialDTO
}
export interface MaterialDTO extends AbstractListItemDTO {
    archive: boolean,
    temporary: boolean|null,
    parent: MaterialDTO|null,
    art: MaterialArtDTO|null,
    orgUnit: OrgUnitDTO|null,
    name: string|null,
    typ: AdditionalFieldResultDTO|null,
    serienNummer: string|null,
    inventarNummer: string|null,
    investNummer: string|null,
    hersteller: EwoHerstellerDTO|null,
    modell: string|null,
    anschaffungsDatum: string|null,
    kommentar: string|null,
    location: EntityStandortDTO|null,
    locationText: string|null,
    primStandort: EntityStandortDTO|null,
    grandStandort: EntityStandortDTO|null,
    profilePhoto: FileDTO|null,
    qrCode: MedProduktQrCodeDTO|null,
    medProduktStandort: boolean,
    materialStandort: boolean,
    childs: MaterialDTO[],
    additionalFieldResults: AdditionalFieldResultDTO[],
    klassen: MaterialArtDTO[],
    toString: string,
    additionalInfos: string|null,
    einweisungPflicht: boolean|null,
    ausgegeben: boolean|null
}
export interface MaterialArtDTO extends AbstractListItemDTO {
    archive: boolean,
    name: string|null,
    klasse: boolean|null,
    inEinweisung: boolean|null,
    typField: AdditionalFieldDTO|null,
    additionalFields: AdditionalFieldDTO[],
    klassen: MaterialArtDTO[],
    dateTypes: MaterialDateTypDTO[],
    passOnChilds: boolean,
    orgUnit: OrgUnitDTO|null
}
export class GenericError {
    creator: string|null
    createTS: string|null
    text: string|null
    archive: string|null
    passOnChilds: string|null
    template: string|null
    rows: string|null|GenericError[]
    released: string|null
    versionNumber: string|null
    form: string|null
    releaseUser: string|null
    releaseDT: string|null
    formName: string|null
    formTyp: string|null
    images: string|null|GenericError[]
    name: string|null
    typ: string|null
    orgUnit: string|null
    currentVersionNumber: string|null
    activeVersionId: string|null
    draftVersionId: string|null
    forms: string|null|GenericError[]
    versions: string|null|GenericError[]
    fields: string|null|GenericError[]
    sortOrder: string|null
    title: string|null
    lineTop: string|null
    lineBottom: string|null
    isDescription: string|null
    isDownload: string|null
    fillRow: string|null
    description: string|null
    file: string|null
    userId: string|null
    creatorId: string|null
    vorlageId: string|null
    ouChain: string|null
    vorlage: string|null
    sent: string|null
    datumStart: string|null
    datumEnde: string|null
    zeitStart: string|null
    zeitEnde: string|null
    content: string|null
    participantId: string|null
    user: string|null
    timestamp: string|null
    chatId: string|null
    mySelf: string|null
    uploaded: string|null
    year: string|null
    month: string|null
    day: string|null
    hour: string|null
    minute: string|null
    second: string|null
    millisecond: string|null
    messages: string|null|GenericError[]
    users: string|null|GenericError[]
    lastactor: string|null
    lastactionDT: string|null
    referenceType: string|null
    reference: string|null
    personenkeis: string|null|GenericError[]
    orgUnits: string|null|GenericError[]
    newMessages: string|null
    dateType: string|null
    date: string|null
    ewo: string|null|GenericError[]
    identifier: string|null
    path: string|null
    size: string|null
    uploadTimestamp: string|null
    comment: string|null
    validUntil: string|null
    cloud: string|null
    mimeType: string|null
    clone: string|null
    fileCategory: string|null
    confidential: string|null
    audit: string|null
    email: string|null
    bezeichnung: string|null
    status: string|null
    personenkreise: string|null|GenericError[]
    medProdukt: string|null|GenericError[]
    geraeteart: string|null|GenericError[]
    foreignElement: string|null
    hersteller: string|null
    modell: string|null
    version: string|null
    standVon: string|null
    searchText: string|null
    fileCategories: string|null|GenericError[]
    typString: string|null
    beschreibung: string|null
    ordnung: string|null
    wert: string|null
    kategorie: string|null
    tree: string|null
    forAll: string|null
    kfz: string|null|GenericError[]
    kfzCategory: string|null|GenericError[]
    modulId: string|null
    object: string|null
    manualFile: string|null
    originalGesehen: string|null
    subObject: string|null
    dates: string|null|GenericError[]
    materialArt: string|null|GenericError[]
    parent: string|null
    konsolidierteAnsicht: string|null
    childs: string|null|GenericError[]
    modul: string|null
    permission: string|null
    permissionGroup: string|null
    ouId: string|null
    personenkreis: string|null
    orgUnitId: string|null
    id: string|null
    additionalText: string|null
    permissions: string|null|GenericError[]
    foreignPermissions: string|null|GenericError[]
    ouMerkmale: string|null|GenericError[]
    rolle: string|null|GenericError[]
    permissionGroups: string|null|GenericError[]
    maxFilesize: string|null
    support: string|null
    dto: string|null
    field: string|null
    maxSize: string|null
    maxFiles: string|null
    allowedTypes: string|null|GenericError[]
    gruppe: string|null
    filePermission: string|null|GenericError[]
    menuIgnorePermissions: string|null|GenericError[]
    files: string|null|GenericError[]
    valid: string|null
    fobiId: string|null
    klasseId: string|null
    stunden: string|null
    soll: string|null
    tnSoll: string|null
    maxGesamt: string|null
    klasse: string|null
    themen: string|null|GenericError[]
    userIds: string|null|GenericError[]
    haben: string|null|GenericError[]
    ignore: string|null
    gesamtStatus: string|null
    zuordnung: string|null
    klassen: string|null|GenericError[]
    themdId: string|null
    course: string|null
    courseId: string|null
    start: string|null
    end: string|null
    prevPlus: string|null
    prevMinus: string|null
    themaSpezGesamt: string|null
    prevZeitraum: string|null
    ausschluss: string|null|GenericError[]
    gesamt: string|null|GenericError[]
    grund: string|null
    zeitraumId: string|null
    overview: string|null
    multi: string|null
    kombiThema: string|null
    dateStart: string|null
    dateEnd: string|null
    timeStart: string|null
    timeEnd: string|null
    veranstalter: string|null
    ort: string|null
    dozent: string|null
    kommentar: string|null
    certificateId: string|null
    certificateStatus: string|null
    issued: string|null
    expiry: string|null
    campusUser: string|null
    dateNachweis: string|null
    nachweisAbweichend: string|null
    nachweise: string|null|GenericError[]
    durationInMinutes: string|null
    published: string|null
    hasTemplate: string|null
    statusId: string|null
    dateFrom: string|null
    dateTo: string|null
    countOnly: string|null
    list: string|null
    tn: string|null
    thema: string|null
    dateTyp: string|null
    intervallWarning: string|null
    intervallReminder: string|null
    intervallTyp: string|null
    infotext: string|null
    formatted: string|null
    reminderPause: string|null
    statusReportPause: string|null
    lionScanPause: string|null
    informUser: string|null
    statusReport: string|null
    syncDate: string|null
    lion: string|null
    emailBusiness: string|null
    phone: string|null
    mobile: string|null
    dateOfBirth: string|null
    qualification: string|null
    validFrom: string|null
    validTo: string|null
    statusNumeric: string|null
    icon: string|null
    personalNo: string|null
    externNo: string|null
    vivendiNo: string|null
    campusId: string|null
    dateEntry: string|null
    dateExit: string|null
    jobScope: string|null
    keinFahrzeugfuehrer: string|null
    licenseClass: string|null
    licenseNo: string|null
    licenseIssuingAuthority: string|null
    licenseIssuingDate: string|null
    rfid: string|null
    forceProfileCheck: string|null
    forcePasswordChange: string|null
    invoiceOrgUnit: string|null
    qualis: string|null|GenericError[]
    hose: string|null
    softshelljacke: string|null
    einsatzjacke: string|null
    einsatzweste: string|null
    poloshirt: string|null
    tshirt: string|null
    pullover: string|null
    schuhe: string|null
    hemd: string|null
    arbeitshandschuhe: string|null
    einmalhandschuhe: string|null
    helm: string|null
    muetzen: string|null
    guertel: string|null
    fleecejacke: string|null
    skills: string|null|GenericError[]
    value: string|null
    lastreminder: string|null
    skill: string|null
    pin: string|null
    pinConfirm: string|null
    categoryMap: string|null|GenericError[]
    rank: string|null
    lockProfile: string|null
    lastname: string|null
    firstname: string|null
    alertEmailBusiness: string|null
    salutation: string|null
    placeOfBirth: string|null
    street: string|null
    houseNo: string|null
    city: string|null
    plz: string|null
    alertSms: string|null
    alertEmail: string|null
    validSms: string|null
    qrCode: string|null
    dressSize: string|null
    profilePhoto: string|null
    consentPublicBirthday: string|null
    consentPublicMobile: string|null
    consentPublicPhone: string|null
    consentProfilePhoto: string|null
    divaModus: string|null
    profilGeprueft: string|null
    group: string|null
    ouText: string|null
    temporary: string|null
    frontendRef: string|null
    specialTyp: string|null
    dienst: string|null
    checkin: string|null
    kennung: string|null|GenericError[]
    sEmail: string|null
    sPassword: string|null
    sPasswordConfirm: string|null
    token: string|null
    passwordOld: string|null
    password: string|null
    passwordConfirm: string|null
    passwortGeaendert: string|null
    taskId: string|null
    onlyUnconfirmed: string|null
    disinfectant: string|null
    concentration: string|null
    einwirkZeit: string|null
    type: string|null
    alternatives: string|null|GenericError[]
    quantifier: string|null
    synonym: string|null
    transmission: string|null
    manufacturer: string|null
    category: string|null|GenericError[]
    opNumber: string|null
    time: string|null
    user1: string|null
    user1Text: string|null
    user2: string|null
    user2Text: string|null
    user3: string|null
    user3Text: string|null
    advice: string|null
    desiRoutine: string|null
    task: string|null
    desinfektorInfo: string|null
    erkrankungVorabbekannt: string|null
    zusatzdaten: string|null
    sentmail: string|null
    seen: string|null
    validateTS: string|null
    validator: string|null
    scopes: string|null|GenericError[]
    protections: string|null|GenericError[]
    medProdukts: string|null|GenericError[]
    materials: string|null|GenericError[]
    desi: string|null
    disease: string|null
    routinedesi: string|null
    ampel: string|null
    scope: string|null|GenericError[]
    protection: string|null|GenericError[]
    archiv: string|null
    selection: string|null|GenericError[]
    forcedBy: string|null|GenericError[]
    ewmpgDateTyp: string|null
    dateText: string|null
    locked: string|null
    switchDate: string|null
    outsourcing: string|null
    inheritCategories: string|null
    caterory: string|null|GenericError[]
    code: string|null
    softwareVersion: string|null
    archiveReason: string|null
    holder: string|null
    createChilds: string|null
    serienNummer: string|null
    udi: string|null
    inventarNummer: string|null
    anschaffungsJahr: string|null
    inbetriebnahmeDatum: string|null
    produktionsDatum: string|null
    ausserbetriebnahmeDatum: string|null
    summary: string|null
    investNummer: string|null
    location: string|null
    locationText: string|null
    primStandort: string|null
    outsourcingLocation: string|null
    ewoObjektbezeichnung: string|null
    ewoHersteller: string|null
    ewoGeraeteart: string|null
    differentChilds: string|null|GenericError[]
    deleteChilds: string|null|GenericError[]
    independentChilds: string|null|GenericError[]
    existingChilds: string|null|GenericError[]
    additionalInfos: string|null
    tag: string|null
    vorgabeText: string|null
    sms: string|null
    maiLExtern: string|null
    own: string|null
    info: string|null|GenericError[]
    count: string|null
    bewertungType: string|null
    tags: string|null|GenericError[]
    required: string|null
    public: string|null
    defaultStatus: string|null
    maxDepth: string|null
    defaultZustaendig: string|null
    allLevelsRequired: string|null
    userIdAll: string|null
    levelId: string|null
    classId: string|null
    includeOpen: string|null
    includeDone: string|null
    onlyPublic: string|null
    specialMode: string|null
    refId: string|null
    forceFill: string|null
    level1: string|null
    level2: string|null
    level3: string|null
    level4: string|null
    level5: string|null
    done: string|null
    risiko: string|null
    chance: string|null
    tragweiteRisiko: string|null
    ewRisiko: string|null
    tragweiteChance: string|null
    ewChance: string|null
    bewertung: string|null
    klassifikationRisiko: string|null
    klassifikationChance: string|null
    zustaendigkeit: string|null
    eskalationsStufe: string|null
    ereignisDatum: string|null
    notiz: string|null
    _oInfoAnPersonenkreise: string|null|GenericError[]
    _oInfoAnEmails: string|null|GenericError[]
    emailAdresse: string|null
    score: string|null
    reportId: string|null
    bewertungen: string|null|GenericError[]
    tragweite: string|null|GenericError[]
    eintrittswahrscheinlichkeiten: string|null|GenericError[]
    level: string|null
    zustaendig: string|null
    eskalation: string|null
    parentZustaendig: string|null
    parentEskalation: string|null
    tagsOu: string|null|GenericError[]
    schweregrad: string|null
    gueltigBis: string|null
    material: string|null
    userQualification: string|null
    userDateTyp: string|null
    kfzDateTyp: string|null
    medProduktDateTyp: string|null
    materialDateTyp: string|null
    publicId: string|null
    entityTto: string|null
    checkinId: string|null
    report: string|null
    anyKfz: string|null
    anyMedProdukt: string|null
    anyMaterial: string|null
    hygieneOnly: string|null
    zurueckBis: string|null
    masterId: string|null
    typId: string|null
    groupCount: string|null
    alternativeDesiAllowed: string|null
    alternativeDaysBefore: string|null
    entityTasks: string|null|GenericError[]
    geerbt: string|null|GenericError[]
    master: string|null
    typText: string|null
    zModus: string|null
    massnahme: string|null
    quelle: string|null
    risiken: string|null
    storno: string|null
    faelligkeit: string|null
    gueltigAb: string|null
    verantwortlich: string|null|GenericError[]
    betroffen: string|null|GenericError[]
    formVersion: string|null
    weitergabeModus: string|null
    runOnLocation: string|null
    medProduktArt: string|null
    checkinBezug: string|null
    formId: string|null
    chat: string|null
    rrule_freq: string|null
    rrule_interval: string|null
    rrule_bysetpos: string|null
    rrule_byweekday: string|null
    rrule_bymonth: string|null
    rrule_bymonthday: string|null
    rrule_daytype: string|null
    rrule_dynamik: string|null
    rrule_daysBefore: string|null
    rrule_prevGen: string|null
    rrule_prevFaellig: string|null
    rrule_nextGen: string|null
    rrule_nextFaellig: string|null
    rrule_lastGen: string|null
    rrule_lastFaellig: string|null
    rrule_text: string|null
    ouMerkmals: string|null|GenericError[]
    invalidatePrev: string|null
    username: string|null
    ou: string|null
    ouArr: string|null|GenericError[]
    constants: string|null
    childChain: string|null|GenericError[]
    downloadFile: string|null
    fileId: string|null
    uploadDate: string|null
    skip: string|null
    skipReason: string|null
    skipUntil: string|null
    neuerStandort: string|null
    zeile: string|null
    errors: string|null|GenericError[]
    createdAt: string|null
    modifiedAt: string|null
    modifier: string|null
    addFields: string|null|GenericError[]
    removed: string|null
    archived: string|null
    class: string|null
    fk: string|null
    parentClass: string|null
    parentFk: string|null
    changes: string|null|GenericError[]
    kfzStandort: string|null
    medProduktStandort: string|null
    materialStandort: string|null
    customLocation: string|null
    abstract: string|null
    reason: string|null
    fileComment: string|null
    externFile: string|null
    fromattedLong: string|null
    formattedLong: string|null
    formattedShort: string|null
    sFormattedShort: string|null
    tempId: string|null
    originalId: string|null
    addFieldId: string|null
    warnings: string|null|GenericError[]
    statusDate: string|null
    statusFile: string|null
    statusEmeldReport: string|null
    fieldName: string|null
    oldValue: string|null
    newValue: string|null
    iconPlain: string|null
    typeText: string|null
    entity: string|null
    data: string|null
    fieldErrors: string|null|GenericError[]
    error: string|null|GenericError[]
    kfzId: string|null
    medProduktId: string|null
    materialId: string|null
    fieldValue: string|null
    fieldTyp: string|null
    qualifikation: string|null
    userText: string|null
    kfzKmBeginn: string|null
    kfzKmEnde: string|null
    checkinField: string|null
    bCheckinBezug: string|null
    vorbelegung: string|null
    filterGeraeteart: string|null
    filterMaterialArt: string|null
    options: string|null|GenericError[]
    kurzname: string|null
    vivendiId: string|null
    statusText: string|null
    ignoreReason: string|null
    anfrageTs: string|null
    antwortTs: string|null
    suche: string|null
    auktion: string|null
    antwortKommentar: string|null
    rang: string|null
    link: string|null
    vivendiBereich: string|null
    portal: string|null
    diva: string|null
    lock: string|null
    inform: string|null
    modus: string|null
    meldeweg: string|null
    warten: string|null
    additionalOus: string|null|GenericError[]
    suchen: string|null|GenericError[]
    anzahl: string|null
    funde: string|null
    qualiJump: string|null
    company: string|null
    locationSummary: string|null
    isoCert: string|null
    sonstigesService: string|null
    allOfOu: string|null
    tagebuchId: string|null
    onlyOpen: string|null
    tagebuchEintragId: string|null
    targetOus: string|null|GenericError[]
    enrich: string|null
    fahrer: string|null
    fahrerText: string|null
    beifahrer: string|null
    beifahrerText: string|null
    dritter: string|null
    dritterText: string|null
    position: string|null
    kmStart: string|null
    kmEnde: string|null
    textCheckin: string|null
    textCheckout: string|null
    checkout: string|null
    checkoutTS: string|null
    reviewed: string|null
    reviewer: string|null
    reviewTS: string|null
    textReview: string|null
    undoneTaskCount: string|null
    totaltaskCount: string|null
    undoneTasksConfirmed: string|null
    ohneFahrerlaubnis: string|null
    undoneTagebuchCount: string|null
    reportCount: string|null
    checkinUsers: string|null|GenericError[]
    actualCheckinUsers: string|null|GenericError[]
    datum: string|null
    zeit: string|null
    startZeit: string|null
    endZeit: string|null
    adresse: string|null
    anzeigenVerfuegbarkeiten: string|null
    anzeigenCheckin: string|null
    beifahrerPflicht: string|null
    kfzPflicht: string|null
    kmPflicht: string|null
    kennungPflicht: string|null
    autoCheckoutStunden: string|null
    ausnahmen: string|null|GenericError[]
    abfragezeiten: string|null|GenericError[]
    nummer: string|null
    superUserPermissionGroup: string|null
    vererbt: string|null
    zeigeName: string|null
    nummerUser: string|null
    standortId: string|null
    vertragsNummer: string|null
    sapNummer: string|null
    smsVertrag: string|null
    rechnungsportalId: string|null
    ansprechpartner: string|null
    ansprechpartnerTelefon: string|null
    ansprechpartnerEmail: string|null
    ansprechpartnerAdresse: string|null
    rechtstraeger: string|null
    dienstzuordnung: string|null
    zeusId: string|null
    module: string|null|GenericError[]
    forbiddenModule: string|null|GenericError[]
    d0: string|null
    d1: string|null
    d2: string|null
    d3: string|null
    d4: string|null
    d5: string|null
    d6: string|null
    pseudo: string|null
    sName: string|null
    merkmale: string|null|GenericError[]
    smsAllowed: string|null
    objektbezeichnung: string|null
    ceNummer: string|null
    einweisungsPflicht: string|null
    verbot: string|null
    kombi: string|null
    maxNutzung: string|null
    maxAlter: string|null
    kombiTyp: string|null
    softwareversionen: string|null|GenericError[]
    softwareSummary: string|null
    einzelEwo: string|null|GenericError[]
    einweisung: string|null
    einweiser: string|null
    einweiserTxt: string|null
    confirmed: string|null
    multiplikator: string|null
    art: string|null
    nachweis: string|null
    gegenstaende: string|null|GenericError[]
    configDTO: string|null
    ewoId: string|null
    materialKlasse: string|null
    website: string|null
    lieferant: string|null
    country: string|null
    columns: string|null|GenericError[]
    requestDto: string|null
    optionDto: string|null
    licensePlate: string|null
    mpgLocation: string|null
    nichtEinsatzbereitKeineTasks: string|null
    materialLocation: string|null
    fremdeigentum: string|null
    chassisNumber: string|null
    firstRegistrationDate: string|null
    constructionYear: string|null
    aufbauer: string|null
    herstellerModell: string|null
    builderNumber: string|null
    caseMoved: string|null
    caseMovedDate: string|null
    tire: string|null
    keyCount: string|null
    repInsurance: string|null
    repInsuranceProvider: string|null
    repInsuranceNumber: string|null
    repInsuranceValidUntilDate: string|null
    repInsuranceValidUntilKm: string|null
    outOfServiceDate: string|null
    quali: string|null
    timestamp_start: string|null
    timestamp_end: string|null
    km_start: string|null
    km_end: string|null
    additional_info: string|null
    result: string|null
    einsatz: string|null
    empfangConfirmed: string|null
    ouIds: string|null|GenericError[]
    includeChildOus: string|null
    parentId: string|null
    withChilds: string|null
    onlyIds: string|null
    medProduktStandortFilter: string|null
    materialStandortFilter: string|null
    materialArtId_arr: string|null|GenericError[]
    materialKlasseId_arr: string|null|GenericError[]
    hasChilds: string|null
    ausgeber: string|null
    empfaenger: string|null
    empfaengerText: string|null
    ruecknehmer: string|null
    dateAusgabe: string|null
    dateRuecknahme: string|null
    verlust: string|null
    anschaffungsDatum: string|null
    grandStandort: string|null
    additionalFieldResults: string|null|GenericError[]
    toString: string|null
    einweisungPflicht: string|null
    ausgegeben: string|null
    inEinweisung: string|null
    typField: string|null
    additionalFields: string|null|GenericError[]
    dateTypes: string|null|GenericError[]
}
export class PermissionModul {
    static readonly Kfz = "KFZ";
    static readonly Kfz_Task_Plan = "KFZ_TASK_PLAN";
    static readonly Kfz_Task = "KFZ_TASK";
    static readonly Kfz_Dates = "KFZ_DATES";
    static readonly Kfz_Files = "KFZ_FILES";
    static readonly Kfz_Settings = "KFZ_SETTINGS";
    static readonly Medprodukt = "MEDPRODUKT";
    static readonly Medprodukt_Task_Plan = "MEDPRODUKT_TASK_PLAN";
    static readonly Medprodukt_Task = "MEDPRODUKT_TASK";
    static readonly Medprodukt_Dates = "MEDPRODUKT_DATES";
    static readonly Medprodukt_Files = "MEDPRODUKT_FILES";
    static readonly Medprodukt_Settings = "MEDPRODUKT_SETTINGS";
    static readonly User = "USER";
    static readonly User_Overview = "USER_Overview";
    static readonly User_Clothes = "USER_CLOTHES";
    static readonly User_PhoneList = "USER_PHONELIST";
    static readonly User_Dates = "USER_DATES";
    static readonly User_Files = "USER_FILES";
    static readonly User_Groups = "USER_GROUPS";
    static readonly User_Settings = "USER_SETTINGS";
    static readonly Task = "TASK";
    static readonly Task_Plan = "TASK_Plan";
    static readonly Task_Settings = "TASK_SETTINGS";
    static readonly Diva = "DIVA";
    static readonly Outsourcing = "OUTSOURCING";
    static readonly Outsourcing_Settings = "OUTSOURCING_SETTINGS";
    static readonly Einsatz = "EINSATZ";
    static readonly Checkin = "CHECKIN";
    static readonly Einsatz_Settings = "EINSATZ_SETTINGS";
    static readonly Hygiene = "HYGIENE";
    static readonly Hygiene_Settings = "HYGIENE_SETTINGS";
    static readonly Emeld = "EMELD";
    static readonly Emeld_Settings = "EMELD_SETTINGS";
    static readonly Fobi = "FOBI";
    static readonly Fobi_Settings = "FOBI_SETTINGS";
    static readonly Lists = "LIST";
    static readonly Material = "MATERIAL";
    static readonly Material_Task_Plan = "MATERIAL_TASK_PLAN";
    static readonly Material_Task = "MATERIAL_TASK";
    static readonly Material_Dates = "MATERIAL_DATES";
    static readonly Material_Files = "MATERIAL_FILES";
    static readonly Material_Settings = "MATERIAL_SETTINGS";
    static readonly MYPROFILE = "MYPROFILE";
    static readonly MYPASSWORD = "MYPASSWORD";
    static readonly Ewmpg = "EWMPG";
    static readonly Einweisung = "EINWEISUNG";
    static readonly FILELEX = "FILELEX";
    static readonly Diary = "DIARY";
    static readonly Manual = "MANUAL";
    static readonly Cloud = "CLOUD";
    static readonly Stammdaten = "STAMMDATEN";
    static readonly Lion = "LION";
    static readonly Audit = "Audit";
    static readonly Superadmin = "Superadmin";
}
export class Permission {
    static readonly Funkrufnamen = 2001;
    static readonly KFZ_Kategorien = 2002;
    static readonly Personalmerkmale = 1007;
    static readonly Qualifikationen = 1008;
    static readonly Notiz = "NOTIZ";
    static readonly Vivendi = 7001;
    static readonly Servicearten = 4001;
    static readonly DesiMassnahmen = 6001;
    static readonly DesiRoutine = 6002;
    static readonly Ampel = 6003;
    static readonly Konzentration = 6004;
    static readonly Umfang = 6005;
    static readonly Schutz = 6006;
    static readonly Einwirkzeit = 6007;
    static readonly Desinfektionsmittel = 6008;
    static readonly Krankheiten = 6009;
    static readonly Vererbung = 6010;
    static readonly Themen = 5001;
    static readonly Kombithemen = 5002;
    static readonly Arten = 5003;
    static readonly Zeitraum = 5004;
    static readonly ListUser = 200;
    static readonly ListKfz = 201;
    static readonly ListMedProdukt = 202;
    static readonly ListMaterial = 203;
    static readonly ListKfzStandorte = 204;
    static readonly ListMedProduktStandorte = 205;
    static readonly ListMaterialStandorte = 206;
    static readonly ListAbrechnungswache = 207;
    static readonly ListFunkrufnamen = 208;
    static readonly ListDienste = 209;
    static readonly ListDiva = 210;
    static readonly MaterialArt = 3001;
    static readonly MaterialKlasse = 3002;
    static readonly Read = 0;
    static readonly History = 1;
    static readonly Create = 10;
    static readonly Import = 11;
    static readonly Import_Daten = 12;
    static readonly Update = 20;
    static readonly Update_Own = 21;
    static readonly Update_Responsible = 22;
    static readonly Update_Vertrauliche = 23;
    static readonly Archive = 40;
    static readonly Delete = 41;
    static readonly Storno = 42;
    static readonly Overview = 50;
    static readonly Overview_Own = 51;
    static readonly Overview_Responsible = 52;
    static readonly Overview_Public = 54;
    static readonly Overview_Chain = 53;
    static readonly Access = 60;
    static readonly Access_Own = 61;
    static readonly Access_Responsible = 62;
    static readonly Access_Vertraulich = 63;
    static readonly Config = 70;
    static readonly Validate = 80;
    static readonly CRUD = 90;
    static readonly Rechtevergabe = 100;
    static readonly Rechtevergabe_sensibel = 101;
    static readonly Dateikategorien = 1001;
    static readonly Dateikategorien_Katalog = 1002;
    static readonly Termine = 1003;
    static readonly Termine_Katalog = 1004;
    static readonly Standorte = 1005;
    static readonly Formulare = 1006;
    static readonly Hersteller = 1007;
    static readonly Vorlagen = 1008;
    static readonly Formulare_Release = 1009;
    static readonly EVD = 1010;
    static readonly Rechtegruppen = 10001;
    static readonly KfzAusbauerHersteller = 10002;
}