
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class InstructionText extends Vue {
  @Prop({ required: true, type: String }) message!: string;

  get instructionText() {
    if (this.message === "datei") {
      return "Wählen Sie oben eine Dateikategorie aus";
    } else if(this.message === "termin") {
      return "Wählen Sie oben eine Terminart aus";
    } else if(this.message === "filter") {
      return "Legen Sie oben einen Filter fest"
    } else {
      return this.message;
    }
  }
}
