import { render, staticRenderFns } from "./EinweisungDataView.vue?vue&type=template&id=afd54dea&scoped=true&"
import script from "./EinweisungDataView.vue?vue&type=script&lang=ts&"
export * from "./EinweisungDataView.vue?vue&type=script&lang=ts&"
import style0 from "./EinweisungDataView.vue?vue&type=style&index=0&id=afd54dea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd54dea",
  null
  
)

export default component.exports