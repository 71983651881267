import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {EinsatzApi} from "@/services/EinsatzApi";
import {EinsatzRequest} from "@/model/Einsatz";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        requestFilter: new EinsatzRequest(),
        vorlagen: [],
        options: [
            ...[{text: 'Status wählen...', value: '', disabled: true}],
            ...[{text: 'Alle', value: 0}, {text: 'Alle offenen', value: 1}, {text: 'Alle ungeprüften', value: 2}],
        ]
    },
    mutations: {
        SET_REQUEST_FILTER(state: any, data: any) {
            state.requestFilter = data
        },
        SET_VORLAGEN(state: any, data: any) {
            state.vorlagen = data
        },
        resetLocationState(state) {
            state.vorlagen = []
        },
    },
    actions: {
        async fetchVorlagen({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.vorlagen && state.vorlagen.length > 0) {
                    resolve(state.vorlagen)
                } else {
                    EinsatzApi.getVorlagenOuChain()
                        .then(data => {
                            commit('SET_VORLAGEN', data)
                            resolve(state.vorlagen)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.einsatzVorlageUpdate:
                    StoreHelper.update("SET_VORLAGEN", state.vorlagen, event, commit)
                    break;
            }
        },
    },
    getters: {},
    modules: {}
}