
import {Component, Vue} from "vue-property-decorator";
import {EinweisungApi} from "@/services/EinweisungApi";
import {DateHelper} from "@/model/Date";
import PseudoTag from "@/components/PseudoTag.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import jquery from "jquery";

@Component({
  components: {ListItemComponent, PseudoTag}
})
export default class UserDashboardEinweisungenCard extends Vue {
  data = null;
  dataEinweiser = null;
  dataUsers = false;
  currentUserId = this.$store.getters.getUser.id;
  dateHelper = DateHelper;
  selectedEinweisung = null;
  donePromise;
  visibleLists = {}


  async init() {
    this.donePromise = new Promise((resolve, reject) => {

      let userId = this.currentUserId;
      Promise.all([
        EinweisungApi.getEinweisungenDashboard(userId, true),
        EinweisungApi.getEinweisungenEinweiserDashboard(userId, true)
      ]).then(([data, dataEinweiser]) => {
        this.data = data;
        this.dataEinweiser = dataEinweiser;

        this.$nextTick(() => {
          jquery('#einweisungcontent').children().appendTo("#myCards");
          resolve(this.data.length + this.dataEinweiser.length);
        });
      });
    })
  }

  getPromise() {
    return this.donePromise;
  }

  async created() {
    await this.init();
  }
  toggleUserList() {
    this.dataUsers = !this.dataUsers;
  }

  get mergedData() {
    const groupByEinweisungId = (dataArray, source) => {
      return dataArray.reduce((acc, current) => {
        const existingEinweisung = acc.find(item => item.id === current.einweisung.id);

        const gegenstand = {
          ...current,
          confirmed: current.confirmed,
          einweiser: current.einweiser,
          einweiserTxt: current.einweiserTxt,
          medProdukt: current.medProdukt,
          kfz: current.kfz,
          material: current.material,
          kfzCategory: current.kfzCategory,
          materialKlasse: current.materialKlasse
        };

        if (existingEinweisung) {
          existingEinweisung.confirmed = existingEinweisung.confirmed && current.confirmed;
          existingEinweisung.gegenstaende.push(gegenstand);
        } else {
          acc.push({
            id: current.einweisung.id,
            datum: current.einweisung.datum,
            confirmed: current.confirmed,
            source: source, // Quelle der Daten
            gegenstaende: [gegenstand]
          });
        }

        return acc;
      }, []);
    };

    const groupedDataFromDashboard = groupByEinweisungId(this.data || [], 'data');
    const groupedDataFromEinweiser = groupByEinweisungId(this.dataEinweiser || [], 'dataEinweiser');

    // Mergen der beiden gruppierten Daten
    return [...groupedDataFromDashboard, ...groupedDataFromEinweiser]
  }


  showModal(einweisung) {
    this.selectedEinweisung = einweisung;
    this.$nextTick(() => {
      this.$root.$emit('bv::show::modal', `modal-${einweisung.id}`);
    });
  }

  async confirmEinweisung(einweisung) {
    this.$confirm('Wollen Sie die Einweisung wirklich bestätigen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(async () => {
      let promise = null;
      if (einweisung.source == 'data') {
        promise = EinweisungApi.putEinweisungUserConfirm(einweisung);
      } else if (einweisung.source == 'dataEinweiser') {
        promise = EinweisungApi.putEinweisungEinweiserConfirm(einweisung);
      } else {
        return;
      }

      promise.then(() => {
        einweisung.confirmed = true;
        this.$root.$emit('bv::hide::modal', `modal-${einweisung.id}`);
      }).catch((e) => {
        this.$alert("Zugriff verweigert!", "Fehler", "error");
      });

    }).catch(() => {
      // do nothing
    });
  }

  getModalTitle(einweisung) {
    return einweisung.source === 'data' ? 'Erhaltene Ein- und Unterweisung' : 'Durchgeführte Ein- und Unterweisung';
  }

  createDto(item) {
    if (item.medProdukt) {
      return item.medProdukt;
    } else if (item.kfz) {
      return item.kfz;
    } else if (item.material) {
      return item.material;
    } else if (item.kfzCategory) {
      return item.kfzCategory;
    } else if (item.materialKlasse) {
      return item.materialKlasse;
    }
    return null;
  }
}
