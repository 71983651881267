
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {Module} from "@/model/Constants";
import MyReportsView from "@/views/emeld/MyReportsView.vue";

@Component(
    {
      components: {MyReportsView},
      computed: {
        Module() {
          return Module
        }
      }
    }
)
export default class CheckinReportList extends mixins<SimpleViewMixin<null, null>>(SimpleViewMixin) {
  @Prop() id

}
