
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, Module} from "@/model/Constants";
import {bus} from "@/main";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {AdditionalFieldHelper, ListItemHelper} from "@/model/Shared";
import {DateHelper} from "@/model/Date";
import {EinsatzRequest} from "@/model/Einsatz";
import {EinsatzApi} from "@/services/EinsatzApi";
import {EmeldApi} from "@/services/EmeldApi";
import {ReportRequest} from "@/model/Emeld";
import {EinsatzDTO, Permission, PermissionModul, ReportDTO, TaskDTO} from "@/model/dto";
import {TaskFormRequet} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (!this.table) return;
          if (val.name.includes("entityform") && !this.$props.id) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()

          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        },
      }
    }
)
export default class EntityFormListServersideView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() id;
  data = null
  filterId = null
  filterOptions = []

  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null

  helper = ListItemHelper

  processing = false;
  clb = () => {
    this.init(true)
  }

  async mounted() {
    this.$root.$emit('loadingStart')
    bus.$on(Event.entityTaskFilterChanged, this.clb)

    await this.init();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    jquery('#' + this.tableId).on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;
      //TODO
    })
  }

  async init(reset = false) {
    this.$root.$emit('loadingStart')
    if (reset) {
      this.filterId = null
      this.filterOptions = []
      this.data = null
    }

    if (this.processing) return;
    this.processing = true
    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      jquery('#' + this.tableId + ' thead').empty();
    }

    let request = null
    request = this.$store.state.task.entityTaskRequestFilter.formDataToRequest()

    //request.includeOpen = true
    this.data = null
    let modulCols = []
    if (this.moduleId == Module.REPORT) {
      request = this.fillRequest(new ReportRequest())
      request.levelId = this.filterId

      this.data = await EmeldApi.getReports(request);

      modulCols = [
        {
          class: '',
          title: 'Ergeignisdatum',
          data: 'ereignisDatum',
          render: function (d, type, row) {
            return DateHelper.parseDate(d);
          }
        },
        {
          class: '',
          title: 'Kategorie',
          data: 'level1.name'
        },
      ]
    } else if (this.moduleId == Module.EINSATZ) {
      request = this.fillRequest(new EinsatzRequest())
      request.vorlageId = this.filterId
      this.data = await EinsatzApi.getEinsaetze(request);

      modulCols = [
        {
          class: '',
          title: 'Einsatzbeginn',
          data: 'datumStart',
          render: function (d, type, row) {
            return DateHelper.parseDate(d);
          }
        }
      ]
    } else if (this.moduleId == Module.TASK) {
      request = this.fillRequest(new TaskFormRequet())
      request.statusId = this.$store.state.task.entityTaskRequestFilter.statusId;
      request.kategorie = this.$store.state.task.entityTaskRequestFilter.kategorie;
      request.formId = this.filterId
      this.data = await TaskApi.getTaskForms(request);
      modulCols = [
        {
          class: '',
          title: 'Fälligkeit',
          data: 'faelligkeit',
          render: function (d, type, row) {
            return DateHelper.parseDate(d);
          }
        },
        {
          class: '',
          title: 'Formular',
          data: 'form.name'
        },
        {
          class: '',
          title: 'Status',
          data: 'status.name'
        },
      ]
    }


    if (!this.data) {
      this.processing = false
      this.filterOptions = []
      this.$root.$emit('loadingDone')

      return;
    }

    if (!this.filterId) {
      this.filterOptions = this.getfilterOptions()
    }

    const fixCols = [
      {
        class: '',
        title: 'ID',
        data: 'id'
      }
    ]


    const cols = this.data["cols"].length ? [
          ...fixCols,
          ...modulCols,
          ...AdditionalFieldHelper.getTableColumns(this.data.cols)
        ] :
        [...fixCols, ...modulCols];
    const rows = this.data["rows"];

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let tableButtons = func.addDefaultButtons([], true)

    let tableOptions = {
      fixedHeader: {
        header: (!self.$props.id),
        headerOffset: 190,
      },
      stateSave: false, //bewusst
      responsive: false,
      rowId: "id",
      order: [[0, "asc"]],
      columns: cols,
      data: rows,
      buttons: tableButtons,
      initComplete: function (settings, json) {
        //TODO
      }
    }

    let table = jquery('#' + this.tableId).DataTable(tableOptions)
    self.table = table
    func.datatableInitComplete(table, self.tableId, self)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })

    this.processing = false
    this.$root.$emit('loadingDone')

  }

  destroyed() {
    bus.$off(Event.entityTaskFilterChanged, this.clb)
    if (this.table) {
      this.table.fixedHeader.disable()
    }
    jquery.contextMenu('destroy')
  }

  get moduleId() {
    return this.$store.state.task.entityTaskRequestFilter.modulId
  }

  getfilterOptions() {
    let defaultFilterOpts = [{text: 'Filtern...', value: null}]
    let modulFilterOpts = []

    if (!this.data || !this.data.rows || this.data.rows.length < 1) {
      return []
    }

    if (this.moduleId == Module.EINSATZ) {
      modulFilterOpts = this.data.rows.map((einsatz: EinsatzDTO) => {
        return {value: einsatz.vorlage.id, text: einsatz.vorlage.name}
      })
    } else if (this.moduleId == Module.REPORT) {
      modulFilterOpts = this.data.rows.map((report: ReportDTO) => {
        return {value: report.level1.id, text: report.level1.name}
      })
    } else if (this.moduleId == Module.TASK) {
      modulFilterOpts = this.data.rows.map((task: TaskDTO) => {
        return {value: task.form.id, text: task.form.name}
      })
    }



    return [...defaultFilterOpts, ...new Map(modulFilterOpts.map(item => [item['value'], item])).values()]
  }
  edit(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    if(this.moduleId == Module.EINSATZ) {
      if (this.$store.getters.hasPermission(PermissionModul.Einsatz, Permission.Overview)) {
        this.table.fixedHeader.disable()
        this.$router.push({path: "/einsatz/checkin/edit/" + id, query: queryParam})
      }
    } else if(this.moduleId == Module.REPORT) {
      if(this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Overview)) {
        this.table.fixedHeader.disable()
        this.$router.push({path: "/emeld/report/edit/" + id, query: queryParam})
      }
    } else if(this.moduleId == Module.TASK) {
      if(this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview)) {
        this.table.fixedHeader.disable()
        this.$router.push({path: "/task/edit/" + id, query: queryParam})
      }
    }
  }

  fillRequest(dto) {
    let request = this.$store.state.task.entityTaskRequestFilter.formDataToRequest(dto)

    const entityKey = this.$router.currentRoute.meta['entityKey'];
    if (!entityKey) {
      return request
    }


    if (this.id) {
      request[entityKey + 'Id'] = this.id
    } else {
      request['any' + entityKey] = true
    }


    return request
  }
}

