import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import {BootstrapVue, TooltipPlugin, VBTooltipPlugin} from 'bootstrap-vue'
import {func} from './scripts/scripts'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import VueScreen from 'vue-screen'
import FsSimpleAlert from "./libs/FsSimpleAlert";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/app.scss'
import '../src/views/FieldsetClassesMixin.ts'
import LiquorTree from 'liquor-tree'
import Toast from "vue-toastification";
import '../src/views/DatatableClassesMixin.ts'
import PrimeVue from 'primevue/config';
import VueCryptojs from 'vue-cryptojs'
import underscore from 'vue-underscore';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueSanitize from "vue-sanitize";
import {FileApi} from "@/services/FileApi";

import "vue-toastification/dist/index.css";


Vue.use(TooltipPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(underscore);
Vue.use(LiquorTree)
Vue.config.productionTip = false
Vue.use(FsSimpleAlert)
Vue.use(BootstrapVue)
Vue.use(VueScreen, 'bootstrap')
Vue.use(Toast, {});
Vue.use(PrimeVue);
Vue.use(VueSanitize, {
    "allowedTags": [
        "address",
        "article",
        "aside",
        "footer",
        "header",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "hgroup",
        "main",
        "nav",
        "section",
        "blockquote",
        "dd",
        "div",
        "dl",
        "dt",
        "figcaption",
        "figure",
        "hr",
        "li",
        "main",
        "ol",
        "p",
        "pre",
        "ul",
        "a",
        "abbr",
        "b",
        "bdi",
        "bdo",
        "br",
        "cite",
        "code",
        "data",
        "dfn",
        "em",
        "i",
        "kbd",
        "mark",
        "q",
        "rb",
        "rp",
        "rt",
        "rtc",
        "ruby",
        "s",
        "samp",
        "small",
        "span",
        "strong",
        "sub",
        "sup",
        "time",
        "u",
        "var",
        "wbr",
        "caption",
        "col",
        "colgroup",
        "table",
        "tbody",
        "td",
        "tfoot",
        "th",
        "thead",
        "tr",
        "img"
    ],
    "disallowedTagsMode": "discard",
    "allowedAttributes": false, /*{
        "span": ["style", "class"],
        "p": ["style", "class"],
        "a": [
            "href",
            "name",
            "target",
        ],
        "img": [
            "src",
            "srcset",
            "alt",
            "title",
            "width",
            "height",
            "loading"
        ]
    },*/
    "selfClosing": [
        "img",
        "br",
        "hr",
        "area",
        "base",
        "basefont",
        "input",
        "link",
        "meta"
    ],
    "allowedSchemes": [
        "http",
        "https",
        "ftp",
        "mailto",
        "tel",
        "data"
    ],
    "allowedSchemesByTag": {},
    "allowedSchemesAppliedToAttributes": [
        "href",
        "src",
        "cite"
    ],
    "allowProtocolRelative": true,
    "enforceHtmlBoundary": false
});
Vue.use(VueCryptojs)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$func = func

NProgress.configure({showSpinner: true})
Vue.filter('prettyjson', function (value) {
    return JSON.stringify(JSON.parse(value), null, 2);
});
Vue.filter('truncate', function (text, length, suffix) {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});

try {
    const response = await fetch('/version.json');
    Vue.prototype.$rewisVersions = await response.json();
} catch (error) {
    console.error('Error loading version data:', error);
}

export const bus = new Vue();

Vue.prototype.$DesktopHeaderOffset = 190;
Vue.prototype.$MobilHeaderOffset = 240;
Vue.prototype.$navBarUmbruch = 1796;

new Vue({
    mixins: [
        Vue.mixin({
            methods: {
                download: function (id, modul = null) {
                    FileApi.download(id+"/"+modul).then((payload) => {
                        if (payload['pdf'] || payload['image']) {
                            this.$root.$emit("fileModal", payload)
                        }
                    }).catch((error) => {
                        this.$alert("Datei konnte nicht geladen werden", "Fehler", "error")
                    })
                },
                pdfDownload: function (id, modul = null) {
                    const w = window.open(process.env.VUE_APP_HOST + "/moduls_new/file/file/" + id + "/" + modul, '_blank');
                    w.document.title = "PDF-Dokument";
                }
            }
        })

    ],
    router,
    store,
    render: h => h(App)
}).$mount('#app')
