

import UserService from '@/services/UserService';
import {Component, Prop, Vue} from "vue-property-decorator";
import {CheckinDTO} from "@/model/dto";
import store from "@/store";
import EntityTtoCheckinTaskSelector from "@/views/checkin/components/EntityTtoCheckinTaskSelector.vue";
import CheckinBuch from "@/views/tagebuch/CheckinBuch.vue";
import {TagebuchApi} from "@/services/TagebuchApi";
import UmgzugView from "@/views/shared/UmgzugView.vue";
import {DateHelper} from "@/model/Date";

@Component({
  components: {UmgzugView, CheckinBuch, EntityTtoCheckinTaskSelector},
  computed: {
    store() {
      return store
    },
    DateHelper() {
      return DateHelper
    }
  }
})
export default class CheckinListItem extends Vue {
  @Prop() checkin
  @Prop() context

  item: CheckinDTO
  showTaskModal = false
  showCheckinBuchModal = false
  showCheckinUmzugModal = false

  created() {
    this.item = this.checkin
  }


  checkout(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.$router.push({
      name: 'checkout'
    })
  }

  edit(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.$router.push({
      name: 'checkin'
    })
  }

  editPersonal(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.$router.push({
      name: 'checkinchangepersonal'
    })
  }

  tasks(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.$router.push('/checkintasklist/' + item.id)
  }

  select(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
  }

  newTask(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.showTaskModal = true
  }

  checkinBuch(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.showCheckinBuchModal = true
  }

  umzug(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.showCheckinUmzugModal = true
  }

  reports(e, item) {
    e.preventDefault()
    this.$store.dispatch('checkin/setCheckin', item)
    this.$router.push('/checkinreportlist/' + item.id)
  }

  reloadTagebuchCount() {
    TagebuchApi.getCheckinCount(this.item.id).then(response => {
      this.item.undoneTagebuchCount = response.count
    })
  }

  userShift(e, item) {
    e.preventDefault()
    const currentUserId = (this.$store.getters.getUser.id)

    let toChangeUser = null
    if (this.item.fahrer && this.item.fahrer.id != currentUserId && this.item.fahrer.id > 0) {
      toChangeUser = this.item.fahrer
    } else if (this.item.beifahrer && this.item.beifahrer.id != currentUserId && this.item.beifahrer.id > 0) {
      toChangeUser = this.item.beifahrer
    }

    if (!toChangeUser) {
      // eslint-disable-next-line eqeqeq
      this.$alert('Es wurde niemand zum Wechseln gefunden.', 'Wechsel nicht möglich.');
      return;
    }

    UserService.checkPinLogin(toChangeUser.id).then(response => {
      this.$router.push({
        name: 'pinlogin',
        params: {
          user: toChangeUser
        }
      })
    }).catch(e => {
      if (e.raw.response.status == 404) {
        this.$alert('Es wurde niemand zum Wechseln gefunden.', 'Wechsel nicht möglich.');
      } else if (e.raw.response.status == 400) {
        this.$alert('Bitte loggen Sie sich mit Ihren normalen Zugangsdaten ein und legen Sie dann durch einen Klick auf das Benutzersymbol oben rechts einen Pin fest.',
            'Kein Pin festgelegt!', 'info')

        this.$router.push('/login')
      } else {
        this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
      }
    })

    // console.log(changeCheckinUser)
    // console.log(item)
  }

  get isActive() {
    if (!this.$store.getters["checkin/getCheckin"]) return false
    return this.item.id == this.$store.getters["checkin/getCheckin"].id
  }
}
