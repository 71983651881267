
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {GenericError, Permission, PermissionModul, TaskDTO, TaskTemplateDTO} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import {TaskTemplate} from "@/model/Task";
import TaskTemplateDataView from "@/views/task/TaskTemplateDataView.vue";
import TaskDataView from "@/views/task/TaskDataView.vue";
import {DateHelper} from "../../model/Date";
import Column from "primevue/column/Column";
import DataTable from "primevue/datatable/DataTable";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  components: {
    TaskDataView,
    TaskTemplateDataView,
    DataTable,
    Column,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class TaskTemplateEditView extends mixins<SimpleViewMixin<TaskTemplateDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data = new TaskTemplate();
  error = new GenericError();
  showTabs = false
  subTasks: TaskDTO[] = []


  async created() {
    this.init();
    this.subTasks = await TaskApi.getSubReferences(this.$props.id)
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = TaskApi.getTask(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = TaskApi.putTaskTemplate(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send taskTemplateChanged event')
        bus.$emit(Event.taskTemplateChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "../tasktable", query: queryParam})
  }

  archiv() {
    bus.$emit(Event.taskArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  get archivTxt() {
    if (!this.$store.getters.hasPermission(this.permissionModul, Permission.Archive)) {
      return null
    }
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }


  get permissionModul() {
    let modul = PermissionModul.Task
    if (this.data.template) {
      modul = PermissionModul.Task_Plan
    }
    return modul;
  }

  get editPermission() {
    return (this.$store.getters.hasPermission(this.permissionModul, Permission.Update))
  }

}
