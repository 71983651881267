
import {Component, Prop, Vue} from 'vue-property-decorator';
import '@/scripts/dataTables_German'
import {ReportDTO} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import {EmeldApi} from "@/services/EmeldApi";
import {Module} from "@/model/Constants";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

@Component({
  computed: {
    Module() {
      return Module
    },
    DateHelper() {
      return DateHelper
    }
  },
  components: {ListItemComponent},
})


export default class EmeldReportCards extends Vue {
  @Prop() list: ReportDTO[]
  @Prop() userDashboard: boolean
  files: any[] = []
  deficiencyInfoData = null
  deficiencyArray = [
    {
      "Oberkategorie" : null,
      "Unterkategorie" : null,
      "Level3" : null,
      "Level4" : null,
      "Level5" : null,
      "Ereignisdatum" : null,
      "BetroffenesProdukt": null,
      "Standort" : null,
      "Prioritaetsstufe": null,
      "BeschreibungDesMangels": null,
      "Mangelbehebung": null,
      "QR-Code": null,
    }
  ];

  modalTitle = ''
  formatLabel(label) {
    switch(label) {
      case 'Oberkategorie':
        return 'Art';
      case 'Unterkategorie':
        return 'Unterkategorie';
      case 'level3':
        return 'Level 3';
      case 'level4':
        return 'Level 4';
      case 'level5':
        return 'Level 5';
      case 'Ereignisdatum':
        return 'Ereignisdatum';
      case 'BetroffenesProdukt':
        return 'Betroffenes Produkt';
      case 'Standort':
        return 'Primärstandort';
      case 'Prioritaetsstufe':
        return 'Prioritätsstufe';
      case 'BeschreibungDesMangels':
        return 'Beschreibung des Mangels';
      case 'Mangelbehebung':
        return 'Mangel behoben?';
      case 'QR-Code':
        return 'QR-Code Nummer';
      default:
        return label;
    }
  }
  notizen(item) {
    this.$emit('notizen', item)
  }

  async getReportData(report) {
    const response = await EmeldApi.getReport(report.id);
    this.getFilesForSelectedTask(report.id);
    this.deficiencyInfoData = response.data;

    const { level1, level2, level3, level4, level5, ereignisDatum, BETROFFENES_MEDIZINPRODUKT, BETROFFENES_MATERIAL, BETROFFENES_KFZ, PRIORITäTSSTUFE, BESCHREIBUNG_DES_MANGELS, KONNTE_DER_MANGEL_BEHOBEN_WERDEN,} = this.deficiencyInfoData;
    this.deficiencyArray = {
      'Oberkategorie': level1?.name,
      'Unterkategorie': level2?.name,
      'level3': level3?.name,
      'level4': level4?.name,
      'level5': level5?.name,
      'Ereignisdatum': DateHelper.parseDate(ereignisDatum),
      'BetroffenesProdukt': this.getAffectedProductOrQRCode(BETROFFENES_MEDIZINPRODUKT, BETROFFENES_MATERIAL, BETROFFENES_KFZ),
      'Standort': this.getPrimStandort(BETROFFENES_MEDIZINPRODUKT, BETROFFENES_MATERIAL),
      'Prioritaetsstufe': PRIORITäTSSTUFE?.fieldValue,
      'BeschreibungDesMangels': BESCHREIBUNG_DES_MANGELS?.fieldValue,
      'Mangelbehebung': KONNTE_DER_MANGEL_BEHOBEN_WERDEN?.fieldValue,
      'QR-Code': this.getAffectedProductOrQRCode(BETROFFENES_MEDIZINPRODUKT, BETROFFENES_MATERIAL, BETROFFENES_KFZ, true),
    };

    this.modalTitle = `${this.deficiencyArray['Oberkategorie']} ID ${report.id}`;
    this.$bvModal.show('report-info-modal');
  }

  getAffectedProductOrQRCode(BETROFFENES_MEDIZINPRODUKT, BETROFFENES_MATERIAL, BETROFFENES_KFZ, qrCode = false) {
    if(!qrCode) {
      if (BETROFFENES_MEDIZINPRODUKT) {
        return BETROFFENES_MEDIZINPRODUKT?.medProdukt[0];
      } else if (BETROFFENES_MATERIAL) {
        return BETROFFENES_MATERIAL?.material[0];
      } else if (BETROFFENES_KFZ) {
        return BETROFFENES_KFZ?.kfz[0];
      }
      return '';
    } else {
      if (BETROFFENES_MEDIZINPRODUKT) {
        return BETROFFENES_MEDIZINPRODUKT.medProdukt[0].qrcode?.name;
      } else if (BETROFFENES_MATERIAL) {
        return BETROFFENES_MATERIAL.material[0].qrcode?.name;
      } else if (BETROFFENES_KFZ) {
        return BETROFFENES_KFZ.kfz[0].qrcode?.name;
      }
      return '';
    }
  }

  getPrimStandort(BETROFFENES_MEDIZINPRODUKT, BETROFFENES_MATERIAL) {
    if (BETROFFENES_MEDIZINPRODUKT) {
      return BETROFFENES_MEDIZINPRODUKT.medProdukt[0]?.primStandort?.text;
    } else if (BETROFFENES_MATERIAL) {
      return BETROFFENES_MATERIAL.material[0]?.primStandort?.text;
    }
    return '';
  }
  async openFilesModal(item) {
    await this.getFilesForSelectedTask(item.id).then(() => {
      this.modalTitle = 'Dateien für ' + item.level1.name
      this.$bvModal.show('report-files-modal')
    }).catch(() => {
      this.$alert("Fehler beim Laden der Dateien", "Fehler", "error")
    })
  }

  async getFilesForSelectedTask(reportId) {
    const response = await EmeldApi.getReport(reportId)
    this.files = response.data.files
  }
}
