
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {EinweisungConfigDTO, PersonenkreisDTO} from "@/model/dto";
import {EinweisungConfig} from "@/model/Einweisung";
import {EinweisungApi} from "@/services/EinweisungApi";
import EinweisungConfigGegenstaendeComponent from "@/views/einweisung/components/EinweisungConfigGegenstaendeComponent.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";

const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  components: {SettingsTooltip, PersonenkreisChooser, EinweisungConfigGegenstaendeComponent}
})
export default class EinweisungConfigCrudView extends mixins<GenericMixin<EinweisungConfigDTO, EinweisungConfigDTO>>(GenericMixin, DataProps) {
  data = new EinweisungConfig();
  error = new EinweisungConfig();
  create = false
  showTabs = false
  personenkreisOptions = []

  async created() {
    this.create = (this.$router.currentRoute.name == 'einweisungconfigcreate')
    if (this.create) {
      this.data.gegenstaende = [];
      this.data.personenkeis = [];
      this.showTabs = true
    } else {
      this.init()
    }
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    let lftNr = 0
    this.personenkreisOptions
        .map((pK: PersonenkreisDTO) => {
          if (pK.temporary) {
            pK.id = (-1) * ++lftNr
          }
          return pK;
        })
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = EinweisungApi.getEinweisungConfig(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }


  onSubmit(goBack: boolean) {
    const p = EinweisungApi.putEinweisungConfig(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        if (this.create) {
          console.log('send einweisungConfigCreated event')
          bus.$emit(Event.einweisungConfigCreated, new ObjectEvent(data.data.id, data.data));
        } else {
          console.log('send einweisungChanged event')
          bus.$emit(Event.einweisungConfigChanged, new ObjectEvent(data.data.id, data.data));
        }
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/einweisung/configoverview", query: queryParam})
  }

  archiv() {
    bus.$emit(Event.einweisungConfigArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get getPersonenkreisOptions() {
    return this.personenkreisOptions
  }
}
