import {OrgUnitDTO, PersonenkreisDTO, TagebuchDTO, TagebuchEintragDTO, TagebuchEintragRequestDTO, TagebuchNotizDTO, UserResponseDTO} from "@/model/dto";
import {AbstractClass} from "@/model/AbstractClasses";
import moment from "moment";

export class TagebuchEintragRequest extends AbstractClass implements TagebuchEintragRequestDTO {
    allOfOu: boolean | null;
    checkinId: number | null;
    dateFrom: string | null;
    dateTo: string | null;
    onlyOpen: boolean | null;
    status: number | null;
    tagebuchId: number | null;
    private _tagebuch: TagebuchDTO | null;


    constructor() {
        super()
        this.dateFrom = moment().subtract(31, 'days').format(moment.HTML5_FMT.DATE);
        this.dateTo = moment().format(moment.HTML5_FMT.DATE);
        this.tagebuchId = null;
        this.onlyOpen = null;
        this.status = null;
        this._tagebuch = new Tagebuch("Übergabebuch");
    }


    set tagebuch(value: TagebuchDTO | null) {
        this._tagebuch = value;
        this.tagebuchId = value?.id;

        if (!this.tagebuchId) {
            this._tagebuch = new Tagebuch("Übergabebuch");
        }
    }

    get tagebuch(): TagebuchDTO | null {
        return this._tagebuch;
    }

    countOnly: boolean | null;
}

export class TagebuchEintrag extends AbstractClass implements TagebuchEintragDTO {
    themen: string[] = [];
    createdAt: string | null;
    creator: UserResponseDTO | null;
    datum: string | null;
    done: boolean | null;
    modifiedAt: string | null;
    modifier: UserResponseDTO | null;
    personenkreise: PersonenkreisDTO[];
    tagebuchId: number | null;
    text: string | null;
    zeit: string | null;
    datumEnde: string | null;
    zeitEnde: string | null;
}

export class Tagebuch extends AbstractClass implements TagebuchDTO {
    createdAt: string | null;
    creator: UserResponseDTO | null;
    modifiedAt: string | null;
    modifier: UserResponseDTO | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    personenkreise: PersonenkreisDTO[] = [];
    targetOus: OrgUnitDTO[] = [];
    themen: string[] = [];


    constructor(name: string | null = null) {
        super();
        this.name = name;
    }
}

export class TagebuchNotiz extends AbstractClass implements TagebuchNotizDTO {
    createTS: string | null;
    creator: UserResponseDTO | null;

    tagebuchEintragId: number | null;
    text: string | null;
}