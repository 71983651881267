
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {Permission, PermissionModul} from "@/model/dto";
import {MedProduktApi} from "@/services/MedProduktApi";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {DateHelper} from "@/model/Date";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";
import {_} from 'vue-underscore';
import {MaterialRequest} from "@/model/Material";
import {MedProduktHelper} from "@/model/MedProdukt";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("medproduktlist") || val.name.includes("medproduktoverview")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")

            if (this.$store.state.route?.from?.name.includes('medproduktoverview') ||
                this.$store.state.route?.from?.name.includes('medproduktlist')) {
              this.init()
            }
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class MedProduktListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  table = null

  showTable = true
  archive = false
  includeChilds = false //childOrgUnits
  withChilds = false //childs MPGs
  windowWith = null;
  thresholdExceeded = true;

  getUrl() {
    return '/medprodukt/medproduktelist';
  }
  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  handleRoute(route: Route) {
    if (route.name === 'medproduktlist' || route.name === 'medproduktoverview') {
      this.showTable = true
      if (this.$router.currentRoute.name === 'medproduktlist') {
        this.withChilds = true;
      } else {
        this.withChilds = false;
      }
    } else {
      this.showTable = false
    }
  }

  mounted() {
    this.checkWith(false);
    this.init()
  }

  async init() {
    this.$root.$emit('loadingStart')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    this.handleRoute(this.$router.currentRoute)
    this.archive = this.$route.query.archive !== undefined
    window.scrollTo(0, 0);

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)


    const request = new MaterialRequest();
    request.archive = this.archive
    request.includeChildOus = this.includeChilds //child orgUnits
    request.withChilds = this.withChilds //childs MPGs


    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.medproduktChanged, Event.medproduktCreated, Event.medproduktArchived])
    const dateHelper = DateHelper


    let childToggle = []
    let order = [[0, "asc"]]
    if (!this.withChilds) {
      childToggle = [{
        className: 'dt-control not-export-col',
        orderable: false,
        data: null,
        defaultContent: ''
      }
      ];
      order = [[1, "asc"]]
    }
    let columns = [
      {
        title: '',
        class: 'dt-control',
        orderable: false,
        data: null,
        defaultContent: '',
        visible: this.withChilds
      },
      {
        title: 'ID',
        class: '',
        data: 'id',
        visible: true
      },
      {
        title: 'Dateien',
        class: 'text-center',
        data: null,
        render: function (data, type, row) {
          return func.getDashboardStatusIcon(data.statusFile, 'file');
        },
        visible: true
      },
      {
        title: 'Termine',
        class: 'text-center',
        data: null,
        render: function (data, type, row) {
          return func.getDashboardStatusIcon(data.statusDate, 'date');
        },
        visible: true
      },
      {
        title: 'Ereignisse',
        class: 'text-center',
        data: null,
        render: function (data, type, row) {
          return func.getDashboardStatusIcon(data.statusEmeldReport, 'report');
        },
        visible: true
      },
      {
        title: 'Geräteart',
        class: '',
        data: 'ewoGeraeteart',
        visible: true
      },
      {
        title: 'Bezeichnung',
        class: '',
        data: 'name',
        visible: true
      },
      {
        title: 'Hersteller',
        class: '',
        data: 'ewoHersteller',
        visible: true
      },
      {
        title: 'CE-Nummer',
        class: 'text-center',
        data: 'ewo.ceNummer',
        visible: true
      },
      {
        title: 'Sn-Nr',
        class: '',
        data: 'serienNummer',
        visible: true
      },
      {
        title: 'UDI',
        class: '',
        data: 'udi',
        visible: true
      },
      {
        title: 'Software',
        class: '',
        data: 'softwareVersion.name',
        visible: true
      },
      {
        title: 'Aktueller Standort',
        class: '',
        data: null,
        render: function (data, type, row) {
          return data.location ? (data.locationText ? data.locationText : ListItemHelper.getTag(data.location)) : "";
        },
        visible: true
      },
      {
        title: 'Bezugsgerät',
        class: '',
        data: null,
        visible: false,
        render: function (data, type, row) {
          if (data.parent) {
            return data.parent.id;
          }
          return data.id;
        }
      },
      {
        title: 'Inventar-Nr.',
        class: 'cw-100',
        data: 'inventarNummer',
        visible: true
      },
      {
        title: 'Invest-Nr.',
        class: 'cw-100',
        data: 'investNummer',
        visible: false
      },
      {
        title: 'QR-Code',
        class: 'cw-100',
        data: 'qrCode.code',
        visible: false
      },
      {
        title: 'Primärstandort',
        class: '',
        data: null,
        render: function (data, type, row) {
          return data.primStandort ? ListItemHelper.getTag(data.primStandort) : "";
        },
        visible: true
      },
      {
        title: 'verwaltender Standort',
        class: '',
        data: null,
        render: function (data, type) {
          return data.orgUnit ? data.orgUnit.name : "";
        },
        visible: true
      },
      {
        title: 'Produktionsdatum',
        class: '',
        data: null,
        visible: false,
        render: function (data, type, row) {
          return dateHelper.parseDate(data.produktionsDatum);
        }
      },
      {
        title: 'Inbetriebnahme',
        class: '',
        data: null,
        visible: false,
        render: function (data, type, row) {
          return dateHelper.parseDate(data.inbetriebnahmeDatum);
        }
      },
      {
        title: 'Außerbetriebnahme',
        class: '',
        data: null,
        visible: false,
        render: function (data, type, row) {
          return dateHelper.parseDate(data.ausserbetriebnahmeDatum);
        }
      },
      {
        title: 'Anschaffungsjahr',
        class: '',
        data: 'anschaffungsJahr',
        visible: false
      },
      {
        title: 'Temporärgerät',
        class: 'cw-150',
        data: null,
        visible: false,
        render(data, type, row) {
          return data.temporary ? 'Ja' : 'Nein';
        }
      },
      {
        title: 'Kommentar',
        class: '',
        data: 'kommentar',
        visible: true
      }
    ];

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: this.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(request);
        },
      }),
      fixedHeader: {
        header: true,
        headerOffset: this.changeOffset(),
      },
      responsive: false,
      order: order,
      rowId: "id",
      columnDefs: [{
        targets: 0,
        "createdCell": function (td, cellData, rowData, row, col) {
          if (!rowData.holder) {
            jquery(td).removeClass('dt-control')
          }
        }
      }],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      columns: childToggle.concat(columns),
      /*initComplete: function (settings, json) {
        //jquery('#withChildSelector').appendTo('.dt-buttons');
      },*/
      buttons: func.addDefaultButtons(myButtons),
    })

    func.datatableInitComplete(table)

    self.table = table
    self.initContextMenu(contextMenuButtons)
    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function (e) {
      if (!self.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Overview)) {
        if (!self.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Overview_Public)) {
          return;
        }
      }

      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;

      self.editMedProdukt(rowId)
    })

    // Add event listener for opening and closing details
    table.on('click', 'td.dt-control', async function (e) {
      let tr = e.target.closest('tr');
      let row = table.row(tr);


      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        if (row.data() == undefined) return;

        // Open this row
        const childRow = await MedProduktHelper.openChildContentByMpg(row.data(), row.data().id);
        row.child(childRow).show();
      }
    });
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Medprodukt, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.createMedProdukt()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Medprodukt, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.editMedProdukt(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.editMedProdukt(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Medprodukt, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/medprodukt/medproduktdata', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            let id = tr.attr('id')
            if (id.includes('_')) {
              let tmpArr = id.split("_")
              id = tmpArr[0]
            }
            bus.$emit(Event.history, new HistoryEvent('/medprodukt/medproduktdata', id))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Medprodukt, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            let id = tr.attr('id')
            if (id.includes('_')) {
              let tmpArr = id.split("_")
              id = tmpArr[0]

              self.archiv({id: id, archive: self.archive})
              self.table.row("#" + tmpArr[1]).child.hide()
            } else {
              var row = self.table.row(tr)
              self.archiv(row.data())
            }
          }
        }, contextMenuButtons
    )

    this.addButton(
        PermissionModul.Medprodukt, Permission.Overview,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, myButtons)
  }
  

  editMedProdukt(id) {

    if (id.includes('_')) {
      let tmpArr = id.split("_")
      id = tmpArr[0]
      this.table.row("#" + tmpArr[1]).child.hide()
    }

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.table.row("#" + id).child.hide()
    this.$router.push({path: "/medprodukt/edit/" + id, query: queryParam})
  }

  createMedProdukt() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/medprodukt/create/", query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: this.$router.currentRoute.name, query: queryParam})
  }

  archiv(data) {
    const title = data.archive ? 'Reaktivieren' : 'Archivieren';
    this.$prompt('Bitte geben Sie einen Grund an.', '', title).then((reason) => {
      MedProduktApi.archiveMedProdukt(data.id, {reason: reason})
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.medproduktArchived, new ObjectEvent(data.id, data));
          })
          .catch((e) => {
            this.$alert(e.error, 'Fehler', 'error');
          })
    });
  }

  initContextMenu(myContextButtons) {
    if (myContextButtons.length == 0) return;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: myContextButtons,
    });
  }

  changeOffset() {
    if(this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }
  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload= true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }
}


