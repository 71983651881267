import {AuktionDTO, CheckinDTO, CheckinPersonalDTO, CheckinRequestDTO, CheckinUserDTO, DienstDTO, KfzKennungDTO, KfzResponseDTO, OrgUnitDTO, UserResponseDTO} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import moment from "moment/moment";
import {AbstractClass} from "@/model/AbstractClasses";


export class Checkin  extends AbstractClass  implements CheckinDTO {
    ohneFahrerlaubnis: boolean;
    archive: boolean | null;
    beifahrer: UserResponseDTO | null;
    beifahrerText: string | null;
    checkinUsers: CheckinUserDTO[] = [];
    checkout: boolean | null;
    checkoutTS: string | null;
    createdAt: string | null;
    creator: UserResponseDTO | null;
    datumEnde: string | null;
    datumStart: string | null;
    dienst: DienstDTO | null;
    dritter: UserResponseDTO | null;
    dritterText: string | null;
    fahrer: UserResponseDTO | null;
    fahrerText: string | null;
    kennung: KfzKennungDTO | null;
    kfz: KfzResponseDTO | null;
    kmEnde: number | null;
    kmStart: number | null;
    modifiedAt: string | null;
    modifier: UserResponseDTO | null;
    orgUnit: OrgUnitDTO | null;
    reviewTS: string | null;
    reviewed: boolean | null;
    reviewer: UserResponseDTO | null;
    textCheckin: string | null;
    textCheckout: string | null;
    textReview: string | null;
    totaltaskCount: number | null;
    undoneTaskCount: number | null;
    undoneTasksConfirmed: boolean | null;
    zeitEnde: string | null;
    zeitStart: string | null;

    static findDienstausnahme(data) {
        if (!data.datumStart) return false;

        const date = DateHelper.getMoment(data.datumStart)
        if (date) {
            const weekday = date.day() + ""
            return data.dienst.ausnahmen.find(ausnahme => ausnahme.tag == weekday)
        }

        return false;
    }

    static handleStartzeit(data:CheckinDTO|AuktionDTO, handleEndzeit = false) {
        data.zeitStart = moment().format(moment.HTML5_FMT.TIME);
        if (!data.dienst) return;

        const ausnahme = Checkin.findDienstausnahme(data)
        if (ausnahme) {
            data.zeitStart = ausnahme.startZeit
            if (handleEndzeit) {
                data.zeitEnde = ausnahme.endZeit
            }
            return;
        }

        if (data.dienst.startZeit) {
            data.zeitStart = data.dienst.startZeit
        }
        if (handleEndzeit) {
            data.zeitEnde = data.dienst.endZeit
        }
    }

    static handleEndzeit(data) {
        data.zeitEnde = moment().format(moment.HTML5_FMT.TIME);
        if (!data.dienst) return;

        const ausnahme = Checkin.findDienstausnahme(data)
        if (ausnahme) {
            data.zeitEnde = ausnahme.endZeit
            return;
        }

        if (data.dienst.endZeit) {
            data.zeitEnde = data.dienst.endZeit
        }
    }

    undoneTagebuchCount: number | null;
    actualCheckinUsers: CheckinUserDTO[] = [];
    reportCount: number | null;
}


export class CheckinRequest implements CheckinRequestDTO {
    archive: boolean | null = false;
    dateFrom: string | null;
    dateTo: string | null;
    onlyOpen: boolean | null;
    ouId: number | null;
    userId: number | null;
    countOnly: boolean | null;
    ouChain: boolean;
    onlyUnconfirmed: boolean | null;
    enrich = true;
}

export class CheckinPersonal  extends AbstractClass  implements CheckinPersonalDTO {
    beifahrer: UserResponseDTO | null;
    beifahrerText: string | null;
    datumStart: string | null;
    dritter: UserResponseDTO | null;
    dritterText: string | null;
    fahrer: UserResponseDTO | null;
    fahrerText: string | null;
    zeitStart: string | null;
}