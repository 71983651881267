
import {Component, Prop} from "vue-property-decorator";
import {EntityTaskDTO, GenericError, TaskDTO, TaskProcessDTO} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import {mixins} from "vue-class-component";
import {Task, TaskProcess, TaskRequest} from "@/model/Task";
import {ApiResponse} from "@/model/AbstractClasses";
import RewisChat from "@/components/RewisChat.vue";
import TaskChatView from "@/views/task/TaskChatView.vue";
import TaskModal from "@/views/task/components/TaskModal.vue";
import TaskChatModal from "@/views/task/components/TaskChatModal.vue";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import {DateHelper} from "@/model/Date";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import TaskCardView from "@/views/task/TaskCardView.vue";
import TaskTableView from "@/views/task/TaskTableView.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import {FormApi} from "@/services/FormApi";
import TaskNotizView from "@/views/task/TaskNotizView.vue";
import DesiCreateView from "@/views/hygiene/DesiCreateView.vue";
import {Event, Module} from "@/model/Constants";
import TaskProcessComponent from "@/components/TaskProcessComponent.vue";
import { bus } from "@/main";

@Component(
    {
      computed: {
        Module() {
          return Module
        }
      },
      components: {
        DesiCreateView,
        TaskProcessComponent,
        TaskNotizView,
        FormInput,
        TaskTableView, TaskCardView, TaskNotizModal, TaskChatModal, TaskModal, TaskChatView, RewisChat
      }
    }
)
export default class CheckinTaskView extends mixins<SimpleViewMixin<TaskDTO, GenericError>>(SimpleViewMixin) {
  @Prop() id
  @Prop({default: 'card'}) modus
  @Prop({default: Task.STATUS_DONE}) newStatus
  showModal = false
  showNotizModal = false
  dateHelper = DateHelper
  selectedItem: TaskDTO | EntityTaskDTO = null;
  data: TaskProcessDTO = new TaskProcess();
  error: GenericError = new GenericError();

  taskStatusOptions = []
  loadingDone = false

  reloadFunction = () => {
    this.init();
  }

  async created() {
    await this.init();
    this.taskStatusOptions = await this.$store.dispatch("task/fetchStatus")
    this.$root.$emit('loadingDone')
  }

  async init() {
    this.initFilter()
    let request = new TaskRequest();
    request.archive = false

    const filter = {...this.$store.getters['task/getTaskRequestFilter']};
    if (filter.statusId) {
      request.statusId = filter.statusId
    }
    this.objects = await TaskApi.getCheckinTasks(this.id);
    this.loadingDone = true
  }
  process(item: TaskDTO) {
    bus.$emit(Event.taskProcess, item);
  }
  hygiene(item) {
    bus.$emit(Event.taskHygieneProcess, item);
  }
  files(item) {
    bus.$emit(Event.taskFilesProcess, item);
  }
  updateCard(data) {
    let obj = this.objects.find(item => item.id == data.id)
    if (obj) {
      obj.status = data.task.status
      obj.addFields = data.task.addFields
    }
  }
  openModal(item) {
    this.selectedItem = item
    this.showModal = true
  }
  notizen(item) {
    this.selectedItem = item
    this.showNotizModal = true
  }

  sort(objects) {
    return objects.sort((a, b) => a.status.sortOrder < b.status.sortOrder ? -1 : 1)
  }

  get filteredStatusOptions() {
    let filtered = this.taskStatusOptions.filter(option => option.value != Task.STATUS_RE && option.value != 4 && option.value != 98) //storno, nichtabgerufen filtern
    return filtered
  }

}
