
import {Vue, Component, Prop} from 'vue-property-decorator';
import {TaskApi} from "@/services/TaskApi";
import {FormApi} from "@/services/FormApi";
import {Task, TaskProcess} from "@/model/Task";
import {GenericError, TaskDTO, TaskProcessDTO} from "@/model/dto";
import {bus} from '@/main';
import {ApiResponse} from "@/model/AbstractClasses";
import {mixins} from "vue-class-component";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import DateForm from "@/components/DateForm.vue";
import {Event, Module} from "@/model/Constants";
import DesiCreateView from "@/views/hygiene/DesiCreateView.vue";
import FormInput from "@/views/form/components/FormInput.vue";

@Component({
  components: {FormInput, DesiCreateView, DateForm},
  computed: {
    Module() {
      return Module
    },
    Task() {
      return Task
    }
  }
})
export default class TaskProcessComponent extends mixins<SimpleViewMixin<TaskProcessDTO, GenericError>>(SimpleViewMixin) {
  @Prop({default: Task.STATUS_DONE}) newStatus
  @Prop({required: true}) options!: any[];
  notesButtonText = "Notizen "
  selectedItem = null;
  data: TaskProcessDTO = new TaskProcess();
  error: GenericError = new GenericError();
  noteCount: number | null = null
  showHygieneModal = false
  formRows = []
  cleanText = ""
  showProcessModal = false;
  isLoading = false;

  mounted() {
    bus.$on(Event.taskProcess, this.loadData);
    bus.$on(Event.taskHygieneProcess, this.hygieneData);
    bus.$on(Event.taskFilesProcess, this.fileData);
  }

  destroyed() {
    bus.$off(Event.taskProcess, this.loadData);
    bus.$off(Event.taskHygieneProcess, this.hygieneData);
    bus.$off(Event.taskFilesProcess, this.fileData);
  }

  getFileLength(files: any) {
    return files ? files.length > 0 : false;
  }

  get filteredOptions() {
    if (!this.selectedItem) {
      return [];
    }
    if (this.selectedItem.typ == 2) { //Maßnahme
      return this.options.filter(option => option.value != Task.STATUS_DONE)
    } else {
      return this.options.filter(option => option.value != Task.STATUS_DONE_RE
          && option.value != Task.STATUS_DONE_WIRKSAM
          && option.value != Task.STATUS_DONE_UNWIRKSAM)
    }

  }

  async loadData(item: Task) {
    const taskResposne = await TaskApi.getTask(item.id)
    this.selectedItem = taskResposne.data
    this.data = new TaskProcess();
    this.error = new GenericError();

    if (item.formVersion) {
      this.formRows = await FormApi.getFormRows({versions: [item.formVersion]});
    } else if (item.form) {
      this.formRows = await FormApi.getFormRows({forms: [item.form]});
    } else {
      this.formRows = [];
    }
    this.notesCounter(item.id);

    this.data.id = item.id;
    this.data.addFields = item.addFields;

    if (this.selectedItem.typ == 2) { // Maßnahme
      this.data.statusId = Task.STATUS_DONE_RE;
    } else {
      if (item.status?.id != Task.STATUS_RE) {
        this.data.statusId = (this.newStatus) ? this.newStatus : item.status?.id
      } else {
        this.data.statusId = Task.STATUS_RE;
      }
    }
    this.data.zurueckBis = item.zurueckBis;
    this.cleanText = this.$sanitize(item.text);
    this.showProcessModal = true;
  }

  hygieneData(item) {
    this.selectedItem = item
    this.showHygieneModal = true
  }
  fileData(item) {
    this.openFilesModal(item);
  }

  submit() {
    this.isLoading = true;
    const p = TaskApi.putMyTask(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      this.isLoading = false;
      if (data instanceof ApiResponse && data.data != undefined) {
        this.selectedItem = this.data.task;
        this.showProcessModal = false
        this.$emit('update', this.data);
      }
    })
  }
  openFilesModal(item) {
    if(item) {
      this.selectedItem = item
    }
    this.$bvModal.show('task-files-modal');
  }
  hygCreated(data) {
    this.selectedItem.status = data.task.status
    this.$emit('update', this.data);
    this.showHygieneModal = false
  }
  async notesCounter(id) {
    try {
      const notes = await TaskApi.getNotizen(id);
      this.noteCount = notes.length;
      if (notes.length == 0) {
        this.notesButtonText = "+ Notizen"
      } else {
        this.notesButtonText = "Notizen"
      }
    } catch (error) {
      console.error("Fehler beim Laden der Notizen:", error);
    }
  }

}
