<template>
  <div class="mr-1">
    <b-form-input type="text" placeholder="Filter..." v-model="filter" class="filter-field d-sm-block my-auto"
           v-if="!noFilter"/>
    <LiquorTree style="font-size: 16px;" v-if="show" :data="data" :filter="filter" :options="options"
                @node:selected="nodeSelected"></LiquorTree>
  </div>
</template>

<script>
import LiquorTree from "liquor-tree";
import {OrgUnitSelectorItemLiquor} from "@/components/model/OrgUnitSelectorItemLiquor";

export default {
  props: [
    'value', 'tree', 'expandAlways', 'noFilter'
  ],
  name: "OrgUnitSelectorLiquor",
  components: {LiquorTree},
  data() {
    return {
      show: false,
      selectedValue: 0,
      filter: '',
      items: [],
      data: [],
      options: {
        paddingLeft: 8,
        parentSelect: true,
        checkbox: false,
        multiple: false,
        filter: {
          emptyText: 'Kein Match gefunden!',
          plainList: true,
          matcher(query, node) {
            let haysack = node.text
            if (node.states && node.states.dto && node.states.dto.searchText) {
              haysack = node.states.dto.searchText
            }
            return new RegExp(query, 'i').test(haysack)
          },
        }
      },
    };
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.show = false

      this.selectedValue = this.$props.value
      this.data = []
      this.$props.tree.forEach((o) => {
        o.name = this.$sanitize(o.name)
        this.data.push(new OrgUnitSelectorItemLiquor(o, this.$props.value, null, this.$props.expandAlways));
      })
      this.data = this.data.sort((a, b) => {
        return a.text.localeCompare(b.text)
      })

      this.$nextTick(() => {
        this.show = true
      });
    },
    nodeSelected(node) {
      this.selectedValue = node.id
      node.states.expanded = true
      this.$emit('locationChange', {id: node.id, name: node.text})
      this.$emit('dtoSelected', node.states.dto)

    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (newValue != this.selectedValue) {
        this.init()
      }
    },
    tree: function (newValue, oldValue) {
      this.init()
    }
  }
};
</script>
<style>
.tree-arrow {
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 10px !important;

}

.tree-anchor {
  padding: 0 !important;
  margin: 0 !important;
}

.tree-content {
  padding-top: 0 !important;
  font-size: 1em;
  margin: 0 !important;
}

.tree-node {
  padding: 0;
  margin: 0 !important;
}
</style>
