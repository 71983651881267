import {apiClient} from './AbstractHTTPService'

import Axios from "axios";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {EinweisungConfigDTO, EinweisungDTO, EinweisungGegenstandDTO, EinweisungOverviewRequestDTO, EinweisungOverviewResponseDTO} from "@/model/dto";
import store from "@/store";
import {Event} from "@/model/Constants";


export abstract class EinweisungApi {
    private static usersAxios = Axios.create();

    static async getEinweisungenList(): Promise<EinweisungDTO[]> {
        const response = await apiClient.get('/ewmpg/einweisungen/list');
        return response.data.data;
    }

    static async putEinweisung(einweisung: EinweisungDTO): Promise<ApiResponse<EinweisungDTO>> {
        const response = await apiClient.put('/ewmpg/einweisungen/' + einweisung.id + '/', einweisung);
        //   store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }

    static async getEinweisung(id): Promise<ApiResponse<EinweisungDTO>> {
        const response = await apiClient.get('/ewmpg/einweisungen/' + id);
        return new ApiResponse(response);
    }

    static async archivEinweisung(id): Promise<any> {
        const response = await apiClient.delete('/ewmpg/einweisungen/' + id);
        //store.dispatch("kfz/outsourcingUpdate")
        return response.data;
    }

    static async putEinweisungConfig(einweisung: EinweisungConfigDTO): Promise<ApiResponse<EinweisungConfigDTO>> {
        const response = await apiClient.put('/ewmpg/einweisungconfig/' + einweisung.id + '/', einweisung);
        store.dispatch("crudEvent", new CrudEvent(Event.einweisungConfigChanged, response.data.data, response.data.data.id));
        return new ApiResponse(response);
    }


    static async getEinweisungConfig(id): Promise<ApiResponse<EinweisungConfigDTO>> {
        const response = await apiClient.get('/ewmpg/einweisungconfig/' + id);
        return new ApiResponse(response);
    }

    static async getEinweisungConfigList(): Promise<EinweisungConfigDTO[]> {
        const response = await apiClient.get('/ewmpg/einweisungconfig/list');
        return response.data.data;
    }

    static async getEinweisungConfigOuChain(): Promise<EinweisungConfigDTO[]> {
        const response = await apiClient.get('/ewmpg/einweisungconfig/chain');
        return response.data.data;
    }


    static async archivEinweisungConfig(id): Promise<any> {
        const response = await apiClient.delete('/ewmpg/einweisungconfig/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.einweisungConfigChanged, response.data.data));
        return response.data;
    }

    static async getOverview(dto: EinweisungOverviewRequestDTO): Promise<EinweisungOverviewResponseDTO> {
        const response = await apiClient.post('ewmpg/einweisungoverview', dto);
        return response.data;
    }

    static async putEinweisungUserConfirm(einweisung: EinweisungDTO): Promise<any> {
        const response = await apiClient.put('/ewmpg/einweisungen/userconfirm/', einweisung);
        return response.data.data
    }
    static async putEinweisungEinweiserConfirm(einweisung: EinweisungDTO): Promise<any> {
        const response = await apiClient.put('/ewmpg/einweisungen/einweiserconfirm/', einweisung);
        return response.data.data
    }

    static async putEinweisungGegenstandConfirm(einweisungGegenstand: EinweisungGegenstandDTO): Promise<any> {
        const response = await apiClient.put('/ewmpg/einweisungen/gegenstandconfirm/', einweisungGegenstand);
        return response.data.data
    }

    static async getEinweisungenDashboard(id, onlyOpen = false): Promise<any> {
        const response = await apiClient.get('/ewmpg/einweisungen/user/' + id + (onlyOpen ? '/open' : ''));
        return response.data.data;
    }

    static async getEinweisungenEinweiserDashboard(id, onlyOpen = false): Promise<any> {
        const response = await apiClient.get('/ewmpg/einweisungen/einweiser/' + id  + (onlyOpen ? '/open' : ''));
        return response.data.data
    }


}