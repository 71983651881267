
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {AuktionDTO, GenericError} from "@/model/dto";
import {Route} from "vue-router";
import moment from "moment";
import {Auktion} from "@/model/Diva";
import {DivaApi} from "@/services/DivaApi";
import AuktionDataView from "@/views/diva/data/AuktionDataView.vue";
import {Fortbildung} from "@/model/Fobi";
import {FobiApi} from "@/services/FobiApi";


@Component({
  components: {
    AuktionDataView,
  },
  watch: {
    '$route'(val: Route) {
      this.init()
    }
  }
})
export default class AuktionCreateView extends mixins<GenericMixin<AuktionDTO, GenericError>>(GenericMixin) {
  data = new Auktion();
  error = new GenericError();

  @Prop() auktionData: Fortbildung

  async created() {
    this.init()
  }

  init() {
    this.data = new Auktion()
    this.data.datumStart = moment().format(moment.HTML5_FMT.DATE);

    if (this.auktionData) {
      const p = DivaApi.getAuktion(this.auktionData.id);
      this.handleApiRequest(p)
      p.then(() => {
        this.data.id = null;
        this.data.lock = false;
        this.data.suchen.forEach(p => p.id = null);
        this.data.suchen.forEach(p => p.lock = false);
        this.data.suchen.forEach(p => p.status = 1);
      })
    }
  }

  onSubmit(send: boolean) {
    const p = DivaApi.putAuktion(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        bus.$emit(Event.divaCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push(this.$store.state.route.from.fullPath)
  }

}
