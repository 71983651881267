
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DateTypDTO, EwoGeraeteartDTO, FileCategoryDTO, MedProduktDateTypDTO, MedProduktDTO, MedProduktEwmpgDateTypDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect/";
import {DateApi} from "@/services/DateApi";
import NumberForm from "@/components/NumberForm.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {MedProduktDateListItem, MedProduktDateType} from "@/model/Date";
import {MedProduktHelper} from "@/model/MedProdukt";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {
    LabelComponent,
    ListItemComponent,
    SimpletableButtonHeader, SettingsTooltip, MedProduktChooser, PseudoTag, NumberForm, Multiselect
  }
})
export default class MedProduktDateSettingsView extends mixins<SimpleViewMixin<MedProduktDateTypDTO, MedProduktDateTypDTO>>(SimpleViewMixin, DataProps) {
  data: MedProduktDateTypDTO = new MedProduktDateType()
  error: MedProduktDateTypDTO = new MedProduktDateType()

  dateTypes: DateTypDTO[] = []

  medproduktOpts: MedProduktDTO[] = []
  medproduktCategoryOpts: EwoGeraeteartDTO[] = []

  chainDateTypes: MedProduktEwmpgDateTypDTO[] = []

  fileCategories: FileCategoryDTO[] = []

  helper = MedProduktHelper

  async created() {
    this.medproduktCategoryOpts = await this.$store.dispatch("medprodukt/fetchGeraeteart")
    this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukteOu")
    this.medproduktOpts = this.medproduktOpts.filter(prod => prod.holder == false)

    this.dateTypes = await this.$store.dispatch("medprodukt/fetchDateTypCatalog")

    await this.init(MedProduktDateType, DateApi.getMedProduktDateTypesOu, (a) => DateApi.putMedProduktDateTypOu(a), (b) => DateApi.removeMedProduktDateTypOu(b))

    this.fileCategories = await this.$store.dispatch('medprodukt/fetchDateiKategorienKatalog');

    this.chainDateTypes = await DateApi.getMedProduktEwmpgDateTypeOverviewChain()
    this.chainDateTypes = this.sort(this.chainDateTypes.filter(dateType => (!dateType.orgUnit || dateType.orgUnit.id != this.$store.getters.getLocation.id)))
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.dateTyp?.name.localeCompare(b.dateTyp?.name))
  }

  removeMedProduktSelection(medProduktId) {
    this.data.medProdukt = this.data.medProdukt.filter(e => e.id != medProduktId)
  }


  get archivTxt() {
    return "Löschen"
  }

  get getDateTypes() {
    return this.dateTypes.sort((a, b) => a.name.localeCompare(b.name))
  }

  get getChainDateTypes() {
    //TODO redundanz
    const tmp = [...new Set(this.chainDateTypes.map(dateType => dateType.formatted))].map(name => new MedProduktDateListItem(name));
    tmp.forEach(
        item => {
          this.chainDateTypes.filter(dateType => dateType.formatted == item.title).forEach(dateType => {
            item.personenkreise = [...item.personenkreise, ...this.personenkreise(dateType)]
            dateType.ewo ? item.ewmpgDateTypes.push(dateType.id) : item.mpgDateTypes.push(dateType.id);
          })
        }
    )

    return tmp
  }


  personenkreise(obj: MedProduktEwmpgDateTypDTO) {
    let arr = [...MedProduktHelper.getTagFromDateType(obj)]

    return arr
  }


}
