
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {SuchenDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import NumberForm from "@/components/NumberForm.vue";
import {Suche} from "@/model/Diva";

@Component({
  computed: {
    Suche() {
      return Suche
    }
  },
  components: {NumberForm, PersonenkreisChooser, Multiselect}
})
export default class SuchenComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: SuchenDTO;
  @Prop() private error: any;
  personenkreisOptions = []
  showModal = false;
  proceedWithFilter = false;

  data: SuchenDTO = null

  async created() {
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.init(this.value)
  }

  init(newValue) {
    this.data = structuredClone(newValue)
  }
  personenKreisChanged() {
    const countTypeZero = this.data.personenkreis.filter(
        (entry) => entry.qualification?.typ === 0
    ).length;
    if(countTypeZero > 1) {
      this.medQualiChange()
    } else {
      this.$emit("input", this.data);
      this.$emit("change", this.data);
    }
  }

  changed() {
    this.$emit("input", this.data);
    this.$emit("change", this.data);
  }
  medQualiChange() {
    this.$confirm(
        'Sie sind im Begriff, Ihre medizinische Qualifikation auszuwechseln. Fortfahren?', '', 'question',
        {
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein'
        }
    )
        .then(() => {
          const indexToRemove = this.data.personenkreis.findIndex(
              entry => entry.qualification?.typ === 0
          );
          if (indexToRemove !== -1) {
            this.data.personenkreis.splice(indexToRemove, 1);
          }
          this.$emit("input", this.data);
          this.$emit("change", this.data);
        })
        .catch(() => {
          if (this.data.personenkreis.length > 0) {
            this.data.personenkreis.splice(this.data.personenkreis.length - 1, 1);
          }
          this.$emit("input", this.data);
          this.$emit("change", this.data);
        });
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
