

import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import DateForm from "@/components/DateForm.vue";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import {_} from 'vue-underscore';
import {HistoryEvent} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";
import Multiselect from "@/libs/multiselect";
import {FileApi} from "@/services/FileApi";
import {DateApi} from "@/services/DateApi";
import {MedProduktDateListItem} from "@/model/Date";
import {MedProduktHelper} from "@/model/MedProdukt";


@Component(
    {
      computed: {
        Module() {
          return Module
        }
      },
      components: {DateForm, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)

export default class MedProduktNavComponent extends mixins(NavMixin) {
  routeName = null
  onChangeDebounced = null
  fileCategories = []
  dateTypes = []
  dateList = []


  navBar = []
  navBarSettings = []

  async created() {
    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.entityTaskFilterChanged)
    }, 1500)

    this.fillNavbar(
        ['medproduktoverview', 'medproduktlist', 'medproduktdatelist', 'medproduktfilelist',
          'medprodukttasktemplatelist', 'medprodukttasklist', 'medproduktimport'],
        this.navBar)

    this.fillNavbar(
        ['medproduktfilecategory', 'medproduktfilecategorycatalog', 'medproduktdatesettings',
          'medproduktdatetypcatalog', 'medproduktstandorte', 'medproduktformlist',
          'ewoimportlist', 'ewosoftwareimportlist'], this.navBarSettings)

    this.handleRoute()

    this.fileCategories = await FileApi.getMedProduktEwmpgFileCategoryOverviewChain();
    this.dateTypes = await DateApi.getMedProduktEwmpgDateTypeOverviewChain()
    this.dateList = [...new Set(this.dateTypes.map(dateType => dateType.formatted))].map(name => new MedProduktDateListItem(name));
    this.dateList.forEach(
        item => {
          this.dateTypes.filter(dateType => dateType.formatted == item.title).forEach(dateType => {
            item.personenkreise = [...item.personenkreise, ...MedProduktHelper.getTagFromDateType(dateType)]
            dateType.ewo ? item.ewmpgDateTypes.push(dateType.id) : item.mpgDateTypes.push(dateType.id);
          })
        }
    )

    this.dateList.push(new MedProduktDateListItem("maximale Nutzungsdauer", 0));
  }

  get fileCategoryOpts() {
    let arr = this.fileCategories.map((ufk) => ufk.fileCategory).sort((a, b) => a.name.localeCompare(b.name))
    return [...new Map(arr.map((item) => [item["id"], item])).values()];
  }

  get fileCategory() {
    return this.$store.state.medprodukt.filter.fileCategory
  }

  set fileCategory(val) {
    this.$store.state.medprodukt.filter.fileCategory = val
    bus.$emit(Event.medProduktFileCategoryFilter, val)
  }

  get dateTyp() {
    return this.$store.state.medprodukt.filter.dateType
  }

  set dateTyp(val) {
    this.$store.state.medprodukt.filter.dateType = val
    bus.$emit(Event.medProduktDateCategoryFilter, val)
  }



  get isFormTabActive(): boolean {
    const regexFormTab = /^\/.*\/edit\/.*\/9(?:[/?#]|$)/;
    return regexFormTab.test(this.$route.path);
  }

  get isTaskTabActive(): boolean {
    const regexTaskTab = /^\/.*\/edit\/.*\/8(?:[/?#]|$)/;
    return regexTaskTab.test(this.$route.path);
  }

  get isHygiene(): boolean {
    const regexTaskTab = /^\/.*\/edit\/.*\/10(?:[/?#]|$)/;
    return regexTaskTab.test(this.$route.path);
  }

  get isDateFormActive(): boolean {
    return this.routeName.includes('tasklist') || this.isFormTabActive || this.isTaskTabActive || this.isHygiene
  }

  handleRoute() {
    this.routeName = this.$route.name
  }
  get status() {
    return this.$store.state.task.entityTaskRequestFilter.statusId
  }
  set status(val) {
    this.$store.state.task.entityTaskRequestFilter.statusId = val
    this.onChangeDebounced()
  }
  get taskStatusOptions() {
    return this.$store.state.task.options;
  }



  get kategorie() {
    return this.$store.state.task.entityTaskRequestFilter.kategorie
  }

  set kategorie(val) {
    this.$store.state.task.entityTaskRequestFilter.kategorie = val
    this.onChangeDebounced()
  }

  get kategorieOptions() {
    let opts = [...[{text: 'Kategorie wählen...', value: '', disabled: true}], ...this.$store.getters['task/getKategorien']]
    opts.push({text: 'Alle', value: null})
    return opts
  }


  get datefrom() {
    return this.$store.state.task.entityTaskRequestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.task.entityTaskRequestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.task.entityTaskRequestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.task.entityTaskRequestFilter.dateTo = val
    this.onChangeDebounced()
  }

  get modul() {
    return this.$store.state.task.entityTaskRequestFilter.modulId
  }

  set modul(val) {
    this.$store.state.task.entityTaskRequestFilter.modulId = val
    this.onChangeDebounced()
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/medprodukt/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Settings, Permission.History)
  }
}

