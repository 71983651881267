import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {TaskRequestDTO} from "@/model/dto";
import {EntityTaskFormRequest, TaskRequest, TaskWeitergabeModus, TaskZModus} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";
import {Event, Module} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        entityTaskRequestFilter: new EntityTaskFormRequest(-1),
        requestFilter: {statusId: '-1', typId: '', ouChain: false, kategorie: null},
        taskZmodus: [{text: 'Auswählen...', value: '', disabled: true},
            {text: 'Eine Person', value: TaskZModus.EINE_PERSON},
            {text: 'Eine Person je Gruppe', value: TaskZModus.GRUPPE},
            {text: 'Alle', value: TaskZModus.ALLE},
            {text: 'Schicht/Dienst einmalig', value: TaskZModus.SCHICHT},
            {text: 'Alle Schichten/Dienste (Bei Checkin)', value: TaskZModus.SCHICHT_ALLE_CHECKIN, disabled: true},
        ],
        taskPreplanningZmodus: [{text: 'Auswählen...', value: '', disabled: true},
            {text: 'Eine Person', value: TaskZModus.EINE_PERSON},
            {text: 'Eine Person je Gruppe', value: TaskZModus.GRUPPE},
            {text: 'Alle', value: TaskZModus.ALLE},
            {text: 'Schicht/Dienst einmalig', value: TaskZModus.SCHICHT},
            {text: 'Schicht/Dienst jede', value: TaskZModus.SCHICHT_JEDE},
            {text: 'Alle Schichten/Dienste (Bei Checkin)', value: TaskZModus.SCHICHT_ALLE_CHECKIN}
        ],
        entityTaskTemplateZModus: [{text: 'Auswählen...', value: '', disabled: true},
            {text: 'Personen-Aufgabe', value: TaskZModus.EINE_PERSON},
            {text: 'Schichtaufgabe', value: TaskZModus.SCHICHT},
            {text: 'Checkin-Aufgabe', value: TaskZModus.CHECKIN},
            {text: 'manuelle Aufgabe', value: TaskZModus.MANUELL},
        ],
        entityTasZModus: [{text: 'Auswählen...', value: '', disabled: true},
            {text: 'Personen-Aufgabe', value: TaskZModus.EINE_PERSON},
            {text: 'Schichtaufgabe', value: TaskZModus.SCHICHT},
        ],
        weitergabeModus: [{text: 'Keine', value: TaskWeitergabeModus.KEINE},
            {text: 'Aktiv', value: TaskWeitergabeModus.AKTIV},
            {text: 'Dienst', value: TaskWeitergabeModus.DIENST},
        ],
        taskTyp: [
            {text: 'Aufgabe', value: 1},
            {text: 'Aufgabe im Maßnahmenplan', value: 2},
            {text: 'Info', value: 4},
        ],
        kategorien: [
            {text: 'Standard', value: 0},
            {text: 'Hygiene', value: 1},
            {text: 'BtM Check', value: 2},
        ],
        entityTaskModuls: [{text: 'Auswählen...', value: null, disabled: true},
            {text: 'Aufgaben', value: Module.TASK},
            {text: 'Einsätze', value: Module.EINSATZ},
            {text: 'Ereignisse', value: Module.REPORT},
        ],
        taskStatus: [],
        entityTaskTemplates: [],
        repetitionText: [
            {text: 'Mehrfach pro Woche', value: 'DAILY'},
            {text: 'Wöchentlich (1x pro Woche)', value: 'WEEKLY'},
            {text: 'Monatlich (1x pro Monat)', value: 'MONTHLY'},
            {text: 'Jährlich (1x pro Jahr)', value: 'YEARLY'},
            {text: 'Tagesintervall', value: 'INTERVAL'},
        ],
        options: [
            ...[{text: 'Status wählen...', value: '', disabled: true}],
            ...[{text: 'Alle', value: null}, {text: 'Unerledigt', value: -1}, {text: 'Überfällig', value: -2}, {text: 'Erledigt', value: 2}],
        ]
    },
    mutations: {
        SET_REQUEST_FILTER(state: any, data: any) {
            state.requestFilter = data
        },
        SET_ENTITY_TASK_TEMPLATES(state: any, data: any) {
            state.entityTaskTemplates = data
        },
        SET_STATUS(state: any, data: any) {
            state.taskStatus = data.map(status => {
                return {text: status.name, value: status.id}
            })
        },
        resetLocationState(state) {
            state.entityTaskTemplates = []
        }
    },
    actions: {
        taskRequestFilterUpdate({commit, state}, value: any) {
            commit('SET_REQUEST_FILTER', value)
        },
        async fetchStatus({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.taskStatus && state.taskStatus.length > 0) {
                    resolve(state.taskStatus)
                } else {
                    TaskApi.getStatus()
                        .then(data => {
                            commit('SET_STATUS', data)
                            resolve(state.taskStatus)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchEntityTaskTemplates({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.entityTaskTemplates && state.entityTaskTemplates.length > 0) {
                    resolve(state.entityTaskTemplates)
                } else {
                    TaskApi.getEntityTaskTemplateList()
                        .then(data => {
                            commit('SET_ENTITY_TASK_TEMPLATES', data)
                            resolve(state.entityTaskTemplates)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.entityTaskTemplateUpdate:
                    StoreHelper.update("SET_ENTITY_TASK_TEMPLATES", state.entityTaskTemplates, event, commit)
                    break;
            }
        },
    },
    getters: {
        getTaskRequestFilter: (state): TaskRequestDTO => {
            return state.requestFilter ? state.requestFilter : new TaskRequest()
        },
        getTaskZmodus: (state: any) => {
            return state.taskZmodus
        },
        getPreplanningTaskZmodus: (state: any) => {
            return state.taskPreplanningZmodus
        },
        getEntityTaskTemplateZmodus: (state: any) => {
            return state.entityTaskTemplateZModus
        },
        getEntityTaskZmodus: (state: any) => {
            return state.entityTasZModus
        },
        getTaskTyp: (state: any) => {
            return state.taskTyp
        },
        getTaskStatus: (state: any) => {
            return state.taskStatus
        },
        getTaskWeitergabeModus: (state: any) => {
            return state.weitergabeModus
        },
        getKategorien: (state: any) => {
            return state.kategorien
        },
        getRepetitionText: (state: any) => {
            return state.repetitionText
        }
    },
    modules: {}
}
