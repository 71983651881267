<template>
  <div :class="isDashboard ? 'p-0 m-0' : 'container-fluid p-0 m-0'">
    <div :class="isDashboard ? '' : 'row d-flex justify-content-center'" id="taskcontent">
      <div class="col-12 mb-4 px-3" v-if="tasks.length === 0 && !isDashboard">Keine Aufgaben und Infos vorhanden</div>
      <div class="col-auto mb-4 d-flex align-content-stretch" v-for="item in tasks" :key="item.id">
        <div class="card card-height shadow-lg">
          <p class="m-0 p-0 pt-1" :class="'icon-' + (item.status?.name || '')">
            <font-awesome-icon v-if="item.typ === 4" class="card-img-top" icon="fa-solid fa-circle-info "/>
            <font-awesome-icon v-else-if="item.kategorie === Task.TASK_CAT_HYGIENE" class="card-img-top" icon="fa-solid fa-spray-can-sparkles"/>
            <font-awesome-icon v-else class="card-img-top" icon="fa-solid fa-clipboard"/>
          </p>

          <div class="card-body text-center overflow-hidden">
            <div class="card-title" style="height: 100%;">
              <h2 class="mt-2 pr-3 pl-3 font-weight-bold">{{ item.title | truncate(38, '...') }}</h2>
              <p v-if="item.id" style="margin-top: -10px;"><small>ID:{{item.id}}</small></p>
              <div v-if="!isCheckin">
                <p v-if="item.betroffen.length > 0">Betrifft: {{ item.betroffen.map(val => val.text).join(", ") }}</p>
                <p v-if="item.orgUnit">Betrifft: {{ item.orgUnit.name }}</p>

              </div>
              <p v-if="item.kfz">
                <list-item-component :dto="item.kfz"></list-item-component>
              </p>
              <p v-if="item.medProdukt">
                <list-item-component :dto="item.medProdukt"></list-item-component>
              </p>
              <p v-if="item.material">
                <list-item-component :dto="item.material"></list-item-component>
              </p>

              <div class="p-0 m-0 text-muted" style="font-size: 0.75rem">
                <!--<div>ID: {{ item.master ? item.master.id : item.id }}</div>-->
                <div class="d-flex justify-content-center" v-if="!isCheckin">Zuständig: {{ item.zustaendig.map(val => val.text).join(", ") }}</div>
                <p v-if="!isCheckin && item.faelligkeit" class="p-0 m-0"
                   :class="{'text-primary': isPastDue(item.faelligkeit), 'text-muted': !isPastDue(item.faelligkeit)}"
                   style="font-size: 0.75rem">
                  Fällig: {{ dateHelper.parseDate(item.faelligkeit) }}
                </p>
              </div>

            </div>

          </div>
          <div class="card-footer text-center" v-if="item.typ !== Task.TASK_TYP_LOGIN">
            <button v-if="!isCheckin && item.kategorie !== Task.TASK_CAT_HYGIENE" title="Chats" class="btn btn-secondary mx-1 btn-area" @click="chats(item)"><i
                class="fas fa-comments"></i></button>
            <button v-if="item.kategorie !== Task.TASK_CAT_HYGIENE"
                    :disabled="!(item.files && item.files.length > 0)"
                    title="Dateien" class="btn btn-secondary mx-1 btn-area"
                    @click="openFilesModal(item)"><i
                    class="fas fa-paperclip"></i></button>
            <button v-if="item.kategorie === Task.TASK_CAT_HYGIENE" title="Hygienemaßnahme erfassen" class="btn btn-success mx-1 btn-area" @click="processHygiene(item)"><i
                class="fas fa-spray-can-sparkles"></i></button>
            <button v-if="item.kategorie !== Task.TASK_CAT_HYGIENE" title="Abarbeiten" class="btn btn-success mx-1 btn-area" @click="process(item)"><i
                class="fas fa-clipboard-check"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DateHelper as dateHelper} from "../../model/Date";
import moment from "moment/moment";
import {Task} from "@/model/Task";
import {func} from "@/scripts/scripts";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {Module} from "@/model/Constants";
import jquery from "jquery";

export default {
  components: {ListItemComponent},
  data() {
    return {
      selectedTask: null
    }
  },
  computed: {
    Module() {
      return Module
    },
    Task() {
      return Task
    },
    dateHelper() {
      return dateHelper
    },
    modalTitle() {
      return this.selectedTask ? `${this.selectedTask.title}: Dateien` : 'Dateien';
    }
  },
  props: ['tasks', 'isCheckin', 'isDashboard'],
  methods: {
    isPastDue(date) {
      return moment().isAfter(moment(date), 'day');
    },
    openModal(item) {
      this.$emit('openModal', item)
    },
    chats(item) {
      this.$emit('chats', item)
    },
    notizen(item) {
      this.$emit('notizen', item)
    },
    process(item) {
      this.$emit('process', item)
    },
    processHygiene(item) {
      if(item.status?.id == Task.STATUS_DONE) {
        this.$confirm(
            'Hygienemaßnahme bereits erfasst. Möchten Sie trotzdem fortfahren?',
            'Bestätigung',
            'question',
            {
              confirmButtonText: 'Trotzdem erfassen',
              cancelButtonText: 'Abbrechen',
            }
        ).then(() => {
          this.$emit('hygiene', item);
        })
      } else {
        this.$emit('hygiene', item);
      }
    },

    openFilesModal(item) {
      this.$emit('fileModal', item);
    },
  }
};
</script>

<style lang="scss">

.icon-Erledigt {
  path {
    fill: green;
  }
}

.icon-Offen {
  path {
    fill: red;
  }
}

.icon-In {
  path {
    fill: #edaf23;
  }
}

</style>
