
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import {GenericError, Permission, PermissionModul, TaskDTO} from "@/model/dto";
import TaskDataView from "@/views/task/TaskDataView.vue";
import {TaskApi} from "@/services/TaskApi";
import {Task, TaskZModus} from "@/model/Task";
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import MaterialDataView from "@/views/material/MaterialDataView.vue";
import EinweisungOverviewTable from "@/views/einweisung/EinweisungOverviewTable.vue";
import TaskModal from "@/views/task/components/TaskModal.vue";
import TaskChatModal from "@/views/task/components/TaskChatModal.vue";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import TaskReportView from "@/views/task/TaskReportView.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import {FormApi} from "@/services/FormApi";
import TaskSubTaskListServersideView from "@/views/task/TaskSubTaskListServersideView.vue";
import TaskChatView from "@/views/task/TaskChatView.vue";
import TaskNotizView from "@/views/task/TaskNotizView.vue";
import TaskStornoModal from "@/views/task/components/TaskStornoModal.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  computed: {
    Module() {
      return Module
    },
    TaskZModus() {
      return TaskZModus
    }
  },
  components: {
    TaskStornoModal,
    TaskSubTaskListServersideView,
    FormInput,
    TaskNotizView,
    TaskChatView,
    TaskReportView,
    TaskNotizModal,
    TaskChatModal, TaskModal,
    EinweisungOverviewTable, MaterialDataView,
    OrgUnitSelectorLiquor,
    TaskDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class TaskEditView extends mixins<GenericMixin<TaskDTO, GenericError>>(GenericMixin, DataProps) {
  @Prop({default: true}) standalone;

  data = new Task();
  error = new GenericError();
  showTabs = false
  locationTree = []
  showStornoModal = false;
  stornoReason = '';
  stornoReasonError = false;

  treeId = this.$props.id
  tabIndex = 0

  @Prop({default: 0}) idx: number

  showModal = false
  showChatModal = false
  showNotizModal = false

  formRows = []

  subTasks: TaskDTO[] = []
  subTaskKey = 0

  async created() {
    await this.init();

    this.loadSubTasks()
    this.locationTree = await TaskApi.getTree(this.$props.id);
    if (!this.locationTree) {
      this.locationTree = []
    } else {
      this.locationTree = [this.locationTree]
    }
  }

  async init() {
    this.tabIndex = Number(this.idx)

    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = TaskApi.getTask(this.$props.id);
    this.handleApiRequest(p).then(() => {
      this.$root.$emit('loadingDone');


      if (this.data.formVersion) {
        FormApi.getFormRows({versions: [this.data.formVersion]}).then(data => {
          this.formRows = data
          this.showTabs = true
        })
      } else if (this.data.form) {
        FormApi.getFormRows({forms: [this.data.form]}).then(data => {
          this.formRows = data
          this.showTabs = true

        })
      } else {
        this.formRows = []
        this.showTabs = true
      }
    })


  }

  storno() {
    this.showStornoModal = true;
  }


  get checkIfStorno() {
    return this.data.storno
  }


  onSubmit(goBack: boolean) {
    const p = TaskApi.putTask(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send taskChanged event')
        bus.$emit(Event.taskChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    const from = this.$store.state.route.from.fullPath
    if (from &&
        this.$store.state.route.from.name != this.$route.name &&
        (from.includes("emeld") || from.includes("task") || from.includes(("kfz/edit")) || from.includes("medprodukt/edit") || from.includes("material/edit"))) {
      this.$router.push(from)
    } else {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }
      this.$router.push({path: "/task/tasktable", query: queryParam})
    }
  }

  archiv() {
    bus.$emit(Event.taskArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  treeClicked($event) {
    this.treeId = $event.taskId
    this.loadSubTasks()
  }

  async loadSubTasks() {
    this.subTasks = await TaskApi.getSubTasks(this.treeId)
    this.subTaskKey++
  }

  get archivTxt() {
    if (!this.$store.getters.hasPermission(this.permissionModul, Permission.Archive)) {
      return null
    }
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get formTask() {
    const data = this.data
    return (!data.passOnChilds && (!data.master || !data.master.passOnChilds)) && data.form
        && (data.zModus == TaskZModus.EINE_PERSON || data.zModus == TaskZModus.SCHICHT || data.zModus == TaskZModus.SCHICHT_JEDE || (data.zModus == TaskZModus.SCHICHT_ALLE_CHECKIN && data.master && data.id != data.master.id))
  }

  @Watch('tabIndex')
  public watchValue(newValue, oldvalue) {
    if (!this.standalone) return;

    const newRoute = "/task/edit/" + this.$props.id + "/" + this.tabIndex
    if (this.$route.fullPath != newRoute) {
      this.$router.replace(newRoute)
    }
  }

  get permissionModul() {
    let modul = PermissionModul.Task
    if (this.data.template) {
      modul = PermissionModul.Task_Plan
    }
    return modul;
  }

  get editPermission() {
    return (this.$store.getters.hasPermission(this.permissionModul, Permission.Update))
  }

  get stornoPermission() {
    return (this.$store.getters.hasPermission(this.permissionModul, Permission.Storno))
  }


}
