import {apiClient, javaClient} from './AbstractHTTPService'
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {
    CampusResultDTO,
    FobiDTO,
    FobiKlasseDTO,
    FobiKombiThemaDTO,
    FobiStatusDTO,
    FobiThemaDTO,
    FobiZeitraumAusschlussDTO,
    FobiZeitraumAusschlussRequestDTO,
    FobiZeitraumDTO,
    FobiZeitraumUserRowDTO
} from "@/model/dto";
import store from "@/store";
import {Event} from "@/model/Constants";
import {FobiZeitraumAusschluss} from "@/model/Fobi";


export abstract class FobiApi {

    static async getFobis(): Promise<FobiDTO[]> {
        const response = await apiClient.get('/fobi/fortbildung/list');
        return response.data.data;
    }

    static async putFobi(Fobi: FobiDTO): Promise<ApiResponse<FobiDTO>> {
        const response = await apiClient.put('/fobi/fortbildung/' + Fobi.id + '/', Fobi);
        //   store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }

    static async getFobi(id): Promise<ApiResponse<FobiDTO>> {
        const response = await apiClient.get('/fobi/fortbildung/' + id);
        return new ApiResponse(response);
    }

    static async archivFobi(id): Promise<any> {
        const response = await apiClient.delete('/fobi/fortbildung/' + id);
        //store.dispatch("kfz/outsourcingUpdate")
        return response.data;
    }


    static async putKlasse(klasse: FobiKlasseDTO): Promise<ApiResponse<FobiKlasseDTO>> {
        const response = await apiClient.put('/fobi/klasse/' + klasse.id + '/', klasse);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiKlasseChanged, response.data.data));
        return new ApiResponse(response);
    }

    static async getKlassen(ouSpecific = false): Promise<FobiKlasseDTO[]> {
        const response = await apiClient.get('/fobi/klasse/' + (ouSpecific ? 'ou' : ''));
        return response.data.data;
    }

    static async archivKlasse(id): Promise<any> {
        const response = await apiClient.delete('/fobi/klasse/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiKlasseChanged, response.data.data));
        return response.data;
    }


    static async putThema(thema: FobiThemaDTO): Promise<ApiResponse<FobiThemaDTO>> {
        const response = await apiClient.put('/fobi/thema/' + thema.id + '/', thema);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiThemaChanged, response.data.data));
        return new ApiResponse(response);
    }

    static async getThema(ouSpecific = false): Promise<FobiThemaDTO[]> {
        const response = await apiClient.get('/fobi/thema/' +  (ouSpecific ? 'ou' : ''));
        return response.data.data;
    }

    static async archiveThema(id): Promise<any> {
        const response = await apiClient.delete('/fobi/thema/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiThemaChanged, response.data.data));

        return response.data;
    }


    static async putKombiThema(thema: FobiKombiThemaDTO): Promise<ApiResponse<FobiKombiThemaDTO>> {
        const response = await apiClient.put('/fobi/kombithema/' + thema.id + '/', thema);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiKombiThemaChanged, response.data.data));
        return new ApiResponse(response);
    }
    static async getKombiThemen(ouSpecific = false): Promise<FobiKombiThemaDTO[]> {
        const response = await apiClient.get('/fobi/kombithema/' +  (ouSpecific ? 'ou' : ''));
        return response.data.data;
    }
    static async getKombiThema(id): Promise<ApiResponse<FobiKombiThemaDTO[]>> {
        const response = await apiClient.get('/fobi/kombithema/' + id);
        return new ApiResponse(response);
    }

    static async archiveKombiThema(id): Promise<any> {
        const response = await apiClient.delete('/fobi/kombithema/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiKombiThemaChanged, response.data.data));

        return response.data;
    }



    static async putZeitraum(thema: FobiZeitraumDTO): Promise<ApiResponse<FobiZeitraumDTO>> {
        const response = await apiClient.put('/fobi/zeitraum/' + thema.id + '/', thema);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiZeitraumChanged, response.data.data));
        return new ApiResponse(response);
    }
    static async getZeitraume(ouSpecific = false): Promise<FobiZeitraumDTO[]> {
        const response = await apiClient.get('/fobi/zeitraum/' +  (ouSpecific ? 'ou' : ''));
        return response.data.data;
    }
    static async getZeitraumeWithUser(userId): Promise<FobiZeitraumDTO[]> {
        const response = await apiClient.get('/fobi/zeitraum/withuser/'+ (userId ? userId : ''));
        return response.data.data;
    }
    static async getZeitraum(id): Promise<ApiResponse<FobiZeitraumDTO[]>> {
        const response = await apiClient.get('/fobi/zeitraum/' + id);
        return new ApiResponse(response);
    }

    static async archiveZeitraum(id): Promise<any> {
        const response = await apiClient.delete('/fobi/zeitraum/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.fobiZeitraumChanged, response.data.data));

        return response.data;
    }


    static async getZeitraumOverview(dto: FobiZeitraumDTO): Promise<FobiZeitraumUserRowDTO[]> {
        const response = await javaClient.post('/fobi/zeitraumoverview', dto);
        return response.data;
    }


    static async getStatus(): Promise<FobiStatusDTO[]> {
        const response = await apiClient.get('/fobi/status/');
        return response.data.data;
    }

    static async getCampusOverview(userId = null): Promise<CampusResultDTO> {
        const response = await apiClient.get('/fobi/campus' + (userId ? '/' + userId : '') );
        return response.data.data;
    }

    static async getCampusOverviewWithChilds(): Promise<CampusResultDTO> {
        const response = await apiClient.get('/fobi/campus/withchilds');
        return response.data.data;
    }

    static async putZeitraumAusschluss(dto: FobiZeitraumAusschlussRequestDTO): Promise<ApiResponse<FobiZeitraumAusschlussDTO>> {
        const response = await apiClient.put('/fobi/zeitraumausschluss/', dto);
        return new ApiResponse(response);
    }
}