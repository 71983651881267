
import {Component, Vue} from "vue-property-decorator";
import {ApiResponse, EventBusEvent, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from "@/main";
import jquery from "jquery";
import {func} from '@/scripts/scripts'

@Component
export default class GenericMixin<T = any, E = any> extends Vue {
  data: T
  selectedItem : T = null
  error: E
  archive = this.$route.query && this.$route.query.archive && this.$route.query.archive == 1
  tableId
  table
  registeredEvents: EventBusEvent[] = []

  addButton(modul, permission, button, buttons = null, contextMenuButton = null, contextMenu = null) {
    if (this.$store.getters.hasPermission(modul, permission)) {
      if (contextMenuButton && contextMenu) {
        contextMenu.push(contextMenuButton)
      }

      if (buttons === null) {
        return [button]
      }
      buttons.push(button)
    }

    return []
  }

  resetErrors() {
    this.$root.$emit('hideAlert')

    // eslint-disable-next-line no-unused-vars
    if (this.error && typeof this.error === 'object') {
      for (const [key, value] of Object.entries(this.error)) {
        this.error[key] = ''
      }
    }
  }

  handleApiRequest(promise, successFeedback = false, setData = true) {
    return new Promise((resolve, reject) => {
      this.resetErrors()
      promise
          .then(d => {
            if (!d.data) {
              this.$root.$emit('alert', 'Unerwarteter Fehler', 'error')
              reject(d)
              return;
            }

            if (setData) {
              this.data = d.data
            }

            if (d.data['warnings'] && d.data['warnings'].length > 0) {
              this.$alert(d.data['warnings'].join('; '), 'Achtung', 'warning')
            }

            if (successFeedback) {
              this.$root.$emit('alert', "Speichern erfolgreich.")
            }

            resolve(d)
          })
          .catch(err => {
            console.log(err)
            if (err.name == "CanceledError") {
              console.log('Request canceled', err.message);
              return reject(err);
            }

            let txts = []
            const mapError = (value) => {
              let tmp = value['name'].split(".");
              if (tmp.length > 1) {
                let tmp2 = this.error;
                console.log("---")


                let last = tmp.pop();
                tmp.forEach((k) => {
                  const arrayRegex = /(.*)\[([0-9]+)\]/gm;
                  let arrayMatch = arrayRegex.exec(k)
                  if (arrayMatch !== null) {
                    tmp2[arrayMatch[1]] = tmp2[arrayMatch[1]] ? tmp2[arrayMatch[1]] : {};
                    tmp2[arrayMatch[1]][arrayMatch[2]] = tmp2[arrayMatch[1]][arrayMatch[2]] ? tmp2[arrayMatch[1]][arrayMatch[2]] : {}
                    tmp2 = tmp2[arrayMatch[1]][arrayMatch[2]]
                  } else {
                    if (!(tmp2[k])) tmp2[k] = {};
                    tmp2 = tmp2[k]
                  }
                });

                Object.assign(tmp2, {[last]: value['status']});
              } else {
                this.error[value['name']] = value['status']
              }
            }
            if (err instanceof ApiResponse) {
              if (Array.isArray(err.fieldErrors) && err.fieldErrors.length > 0) {
                txts.push("Speichern fehlgeschlagen! Validierungsfehler.")

                if (!this.error) {
                  this.$root.$emit('alert', 'Bitte alle Pflichtfelder ausfüllen!', 'warning')
                } else {
                  Object.entries(err.fieldErrors).forEach(
                      ([key, value]) => mapError(value)
                  );
                }
              }

              if (err.error != undefined) {
                if (Array.isArray(err.error)) {
                  Object.entries(err.error).forEach(
                      ([key, value]) => txts.push(value.text)
                  );
                } else {
                  txts.push(err.error)
                }
              }

              if (txts.length > 0) this.$root.$emit('alert', txts.join('; '), 'warning')
            } else {
              this.$root.$emit('alert', 'Unerwarteter Fehler', 'error')
            }
            resolve(err)
          })
    })
  }

  unregisterEvents() {
    this.registeredEvents.forEach(event => {
      bus.$off(event.name, event.callback)
    })
  }

  registerEvents(events: string[]) {
    events.push("fixedheader");

    events.forEach(event => {
      const eventLower = event.toLowerCase();
      let func = null

      if (eventLower.includes("fixedheader")) {
        func = (payload) => {
          if (this.table && this.table.settings()[0]._fixedHeader && this.table.settings()[0]._fixedHeader.c.header) {
            window.scrollTo(0, 0);

            this.table.fixedHeader.disable()
            setTimeout(() => {
              this.table.fixedHeader.adjust()
              this.table.fixedHeader.enable()
            }, 1000);
          }
        }
      } else if (eventLower.includes("create")) {
        func = (payload: ObjectEvent<T>) => {
          console.log('received ' + event + ' event')
          let table = jquery('#' + this.tableId).DataTable();

          if (!this.archive) {
            table.row.add(payload.data).draw()
          }
        }
      } else if (eventLower.includes("change")) {
        func = (payload: ObjectEvent<T>) => {
          let rowId = "#" + payload.id;
          console.log('received ' + event + ' event')
          let table = jquery('#' + this.tableId).DataTable();
          let data = table.row(rowId).data();
          if (!data) {
            console.log("no data found")
            return
          }


          for (const [key, value] of Object.entries(data)) {
            if (payload.data[key] === undefined) {
              payload.data[key] = value
            }
          }

          table.row(rowId).data(payload.data)
        }
      } else if (eventLower.includes("archive")) {
        func = (payload: ObjectEvent<T>) => {
          let rowId = "#" + payload.id;
          console.log('received ' + event + ' event')
          let table = jquery('#' + this.tableId).DataTable();

          if (!this.archive) {
            if (!payload.data.archive) {
              table.row.add(payload.data).draw()
            } else {
              table.row(rowId).remove().draw()
            }
          } else {
            if (!payload.data.archive) {
              table.row(rowId).remove().draw()
            } else {
              table.row.add(payload.data).draw()
            }
          }
        }
      }

      if (!func) return;

      const regEvent = new EventBusEvent();
      regEvent.name = event;
      regEvent.callback = func;
      this.registeredEvents.push(regEvent);
      bus.$on(event, func)


    })
  }

  getUrl() {
    return '';
  }

  handleDatableRoute(contextMenu) {
    var tmp = this.$route.query.archive !== undefined;
    if (tmp != this.archive) {
      this.archive = tmp
      if (contextMenu) this.initContextMenu()
      this.reloadTable()
    }
  }

  initContextMenu(myContextButtons = null) {
    if (!myContextButtons) {
      jquery.contextMenu('destroy');
      return;
    }

    if (myContextButtons.length == 0) return
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr:not(.foreign-row)';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: myContextButtons,
    });
  }


  reloadTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.url(func.rewisUrl(this.getUrl())).load(() => {
      this.$root.$emit('loadingDone')
    });
  }
}
