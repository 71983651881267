import CheckinMask from "@/views/checkin/CheckinMask.vue";
import CheckoutMask from "@/views/checkin/CheckoutMask.vue";
import CheckinChangePersonalMask from "@/views/checkin/CheckinChangePersonalMask.vue";
import CheckinDashboard from "@/views/checkin/CheckinDashboard.vue";
import CheckinTaskView from "@/views/checkin/CheckinTaskView.vue";
import {Permission, PermissionModul} from "@/model/dto";
import CheckinReportList from "@/views/checkin/CheckinReportList.vue";

export const checkinRoutes = [
    {
        path: 'checkinlist',
        name: 'checkinlist',
        component: CheckinDashboard,
        meta: {
            loading: true,
            permission: [PermissionModul.Checkin, Permission.Create]
        }
    },
    {
        path: 'checkintasklist/:id',
        name: 'checkintasklist',
        component: CheckinTaskView,
        props: true,
        meta: {
            loading: true,
            permission: [PermissionModul.Checkin, Permission.Create]
        }
    },
    {
        path: 'checkinreportlist/:id',
        name: 'checkinreportlist',
        component: CheckinReportList,
        props: true,
        meta: {
            loading: true,
            permission: [PermissionModul.Checkin, Permission.Create]
        }
    },
    {
        path: 'checkin',
        name: 'checkin',
        component: CheckinMask,
        meta: {
            loading: true,
            permission: [PermissionModul.Checkin, Permission.Create]
        }
    },
    {
        path: 'checkout',
        name: 'checkout',
        component: CheckoutMask,
        meta: {
            loading: true,
            permission: [PermissionModul.Checkin, Permission.Create]
        }
    },
    {
        path: 'checkinchangepersonal',
        name: 'checkinchangepersonal',
        component: CheckinChangePersonalMask,
        meta: {
            loading: true,
            permission: [PermissionModul.Checkin, Permission.Create]
        }
    },
]

