
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import TaskNavComponent from "@/views/task/components/TaskNavComponent.vue";
import {TaskApi} from "@/services/TaskApi";
import {EntityTaskTemplateDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import GenericMixin from "@/views/GenericMixin.vue";
import {mixins} from "vue-class-component";
import moment from "moment";

const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component(
    {
      components: {TaskNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class TaskView extends mixins<GenericMixin<EntityTaskTemplateDTO, GenericError>>(GenericMixin, DataProps) {

  tableRoute = true
  showModal = false;
  taskData = null;

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'task' && this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview)) {
      this.$router.push("/task/tasktable")
    } else if (this.$route.name == 'task' && this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview_Own)) {
      this.$router.push("/task/mytask")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.taskArchiveRequest, (payload: ObjectEvent<any>) => this.archivTask(payload.data))
  }

  destroyed() {
    bus.$off(Event.taskArchiveRequest)
  }

  archivTask(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren';
    if (!data.gueltigBis && !data.archive && this.$route.name === 'tasktable') {
      this.$confirm('Wollen Sie Aufgabe ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        this.taskData = data;
        this.showModal = true;
      })
    } else {
      this.$confirm('Wollen Sie Aufgabe ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        TaskApi.archivTask(data.id)
            .then(() => {
              data.archive = !data.archive;
              bus.$emit(Event.taskArchived, new ObjectEvent(data.id, data));
            })
            .catch(() => {
              this.$alert("Es ist ein Fehler aufgetreten");
            });
      });
    }
  }

  endTaskModal() {
    this.handleTaskArchiving(true);
  }

  letActiveModal() {
    this.handleTaskArchiving(false);
  }

  async handleTaskArchiving(endTask = false) {
    if (endTask) {
      const p = await TaskApi.getTask(this.taskData.id);
      this.taskData = p.data;

      this.taskData.gueltigBis = moment().format(moment.HTML5_FMT.DATE);

      const d = TaskApi.putTask(this.taskData);
      this.handleApiRequest(d, true).then(async (data) => {
        TaskApi.archivTask(this.taskData.id).then(() => {
          this.taskData.archive = !this.taskData.archive;
          bus.$emit(Event.taskArchived, new ObjectEvent(this.taskData.id, this.taskData));
        });
      });
    } else {
      TaskApi.archivTask(this.taskData.id).then(() => {
        this.taskData.archive = !this.taskData.archive;
        bus.$emit(Event.taskArchived, new ObjectEvent(this.taskData.id, this.taskData));
      });
    }
    this.showModal = false;
  }


}
