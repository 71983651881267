
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EntityTaskTemplateDTO, GenericError} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import LabelComponent from "@/components/LabelComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import {EntityTaskTemplateObject, Task} from "@/model/Task";
import EntityTaskTemplateEntityData from "@/views/task/entity/EntityTaskTemplateEntityData.vue";
import NumberForm from "@/components/NumberForm.vue";
import {Module} from "@/model/Constants";
import Column from "primevue/column/Column";
import DataTable from "primevue/datatable/DataTable";
import {DateHelper} from "../../../model/Date";

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    },
    Module() {
      return Module
    },
    Task() {
      return Task
    }
  },
  components: {
    Column,
    DataTable,
    NumberForm,
    EntityTaskTemplateEntityData,
    PersonenkreisChooser,
    OrgUnitSelector,
    HtmlEditor,
    FileUpload,
    LabelComponent,
    Multiselect
  }
})
export default class EntityTaskTemplateDataView extends mixins<GenericMixin<EntityTaskTemplateDTO, GenericError>>(GenericMixin, DropzoneMixin) {
  @Prop() value: EntityTaskTemplateDTO;
  @Prop() error: GenericError;
  @Prop() modul;
  @Prop({default: false}) foreignTask;

  data: EntityTaskTemplateDTO = this.$props.value;
  showModal = false;
  uploadKey = 0
  personenkreisOptions = []
  formOptions = []
  scopeOptions = []
  desiRoutines = []

  async created() {
    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == this.modul)
    this.scopeOptions = await this.$store.dispatch("hygiene/fetchScopes")
    this.desiRoutines = await this.$store.dispatch("hygiene/fetchDesiRoutines")
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
  }


  removeEntity(entityID) {
    this.data.entityTasks = this.data.entityTasks.filter(task => task.id !== entityID);
    this.selectedItem = null;
    this.$refs.dataTable.selection = null
  }

  getFrequenz(d) {
    if (d.rrule_freq) {
      const repetitionTexts = this.$store.getters['task/getRepetitionText'];
      const repetitionOption = repetitionTexts.find(option => option.value === d.rrule_freq);
      return repetitionOption.text;
    } else {
      return '';
    }
  }

  getModus(d) {
    if (d.zModus) {
      const modus = this.$store.getters['task/getEntityTaskTemplateZmodus'].filter(v => v.value === d.zModus).map(v => v.text).join(",")
      return modus;
    } else {
      return '';
    }

  }

  editObject(data) {
    this.showModal = true;
  }

  addEntity() {
    let tag = new EntityTaskTemplateObject();
    this.data.entityTasks.push(tag);
    this.selectedItem = tag;
    this.showModal = true;
  }

  get isKfz() {
    return this.$route.name.includes("kfz")
  }


  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.$emit("input", val)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.uploadKey++
  }
}
