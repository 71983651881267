
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, UserId} from "@/model/Constants";
import {CheckinDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import {Checkin} from "@/model/Checkin";
import {CheckinApi} from "@/services/CheckinApi";
import CheckinTaskView from "@/views/checkin/CheckinTaskView.vue";
import CheckinListItem from "@/views/checkin/components/CheckinListItem.vue";
import {DateHelper} from "../../model/Date";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  computed: {
    UserId() {
      return UserId
    },
    DateHelper() {
      return DateHelper
    }
  },
  components: {CheckinListItem, CheckinTaskView},
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class CheckinEditView extends mixins<GenericMixin<CheckinDTO, GenericError>>(GenericMixin, DataProps) {
  data = new Checkin();
  error = new GenericError();
  showTabs = false
  tabIndex = 0

  async created() {
    this.init();
  }


  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = CheckinApi.getCheckin(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean, send) {
    const p = CheckinApi.putCheckin(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send checkinChanged event')
        bus.$emit(Event.checkinChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    const from = this.$store.state.route.from.fullPath
    if (from &&
        this.$store.state.route.from.name != this.$route.name &&
        (from.includes("kfz/edit") || from.includes("medprodukt/edit") || from.includes("material/edit"))) {
      this.$router.push(from)
    } else {
      this.$router.push("/einsatz/checkinoverview")
    }
  }

  get validatePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Checkin, Permission.Validate);
  }

}
