import { render, staticRenderFns } from "./MedProduktChooser.vue?vue&type=template&id=ed93de6a&scoped=true&"
import script from "./MedProduktChooser.vue?vue&type=script&lang=ts&"
export * from "./MedProduktChooser.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed93de6a",
  null
  
)

export default component.exports