
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FileCategoryDTO, GenericError, PersonenkreisDTO, SuchenPersonDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";


import Column from "primevue/column/Column";
import DataTable from "primevue/datatable/DataTable";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import ContextMenu from "primevue/contextmenu/ContextMenu";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import {SuchenPerson} from "@/model/Diva";
import {DivaApi} from "@/services/DivaApi";
import DivaSuche from "@/views/diva/components/DivaSuche.vue";
import {DateHelper} from "../../model/Date";

@Component({
  computed: {
    SuchenPerson() {
      return SuchenPerson
    },
    DateHelper() {
      return DateHelper
    },
  },
  components: {
    DivaSuche,
    LabelComponent,
    ListItemComponent,
    ChainIconTooltipTableCell,
    DataTable, Column, ContextMenu,
    SimpletableButtonHeader, SettingsTooltip
  }
})
export default class PortalView extends mixins<SimpleViewMixin<SuchenPersonDTO, GenericError>>(SimpleViewMixin) {
  data: SuchenPersonDTO = new SuchenPerson()
  test = true;
  error: GenericError = new GenericError()
  fileCategories: FileCategoryDTO[] = []
  personenkreisOptions: PersonenkreisDTO[] = []
  columnVisibility = {
    ID: true,
    Standort: true,
    Datum: true,
    Beginn: true,
    DatumEnde: true,
    Ende: true,
    Personenkreis: true,
    Dienst: true,
    Status: true,
  };
  tableKey = 0
  redraw() {
    this.tableKey++
  }


  async created() {
    await this.init(SuchenPerson,
        () => DivaApi.getPortal(),
        (a) => DivaApi.answerPortal(a),
        (b) => {
          return;
        }
    )
    this.$root.$emit('loadingDone')
  }

  editObject(dto) {
    if (dto.status != SuchenPerson.STATUS_NA && dto.status != SuchenPerson.STATUS_Angefragt) {
      return;
    }
    this.data = structuredClone(dto)
    this.data.status = SuchenPerson.STATUS_Zugesagt
    this.createErrorDto()
    this.showModal = true
  }
}
